import React, { useEffect, useState } from "react";
import axios from "axios"; // Import Axios for making HTTP requests
import "./Admin.css";
import Imp from "./Imp";
import baseURL from "../../config/baseURl";

const Admin = () => {
  // State variables to hold form data
  const [exam, setExam] = useState("");
  const [subexam, setSubexam] = useState("");
  const [test, setTest] = useState([]);
  const [marks, setMarks] = useState("");
  const [level, setLevel] = useState("");
  const [time, setTime] = useState("");
  const [cost, setCost] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(typeof test);
    const TE=JSON.parse(test);
    try {
      const response = await axios.post(`${baseURL}/admin`, {
        exam: exam,
        subexam: subexam,
        test: TE,
        marks: marks,
        level: level,
        time: time,
        cost: cost,
      });
      alert(response.data.message); // Log the response message
    } catch (error) {
      alert("Error uploading test:", error.message);
    }
  };
  const [display, setdisplay] = useState("0");
  const [auth, setauth] = useState("0");
  const handleSubmitAuth = async (e) => {
    const EM = "zX5qYp3s@example.com";
    const PASS = "9#W&4vZp$@u8";
    e.preventDefault();
    if (email === EM && password === PASS) {
      setauth("1");
    } else if (email === EM) {
      alert("Password is incorrect");
    } else if (password === PASS) {
      alert("Email is incorrect");
    } else {
      alert("Email/Password is incorrect");
    }
  };
  //eslint-disable-next-line
  const [exams, setExams] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
       
        const response = await axios.get(`${baseURL}/exams`);
        console.log(response.data);
        setExams(response.data);
      } catch (error) {
        console.error("Error fetching exams:", error.message);
      }
    };
    fetchData();
  }, []);

  if (auth === "2") {
    return (
      <div className="auth">
        <div className="auth_email">
          <input
            type="email"
            value={email}
            onChange={(e) => setemail(e.target.value)}
            className="auth_ele"
            placeholder="EMAIL"
            required
          />
        </div>
        <div className="auth_password">
          <input
            type="password"
            value={password}
            onChange={(e) => setpassword(e.target.value)}
            className="auth_ele"
            placeholder="PASSWORD"
            required
          />
        </div>
        <div className="auth_btn" onClick={handleSubmitAuth}>
          ENTER
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <h2 className="sria">SHIV RADHYA IAS</h2>
        <div className="btns_admin">
          <div className="btn_s" onClick={() => setdisplay("0")}>
            TEST UPLOAD
          </div>
          <div className="btn_s" onClick={() => setdisplay("1")}>
            ARTICLES UPLOAD
          </div>
        </div>
        {display === "0" && (
          <form onSubmit={handleSubmit}>
            {/* Dropdown for selecting exam */}
            <div>
              <select
                id="exam"
                value={exam}
                onChange={(e) => setExam(e.target.value)}
              >
                <option value="">Select Exam</option>
                <option value="exam1">Exam 1</option>
                <option value="exam2">Exam 2</option>
                {/* Add more options as needed */}
              </select>
              <div className="or">OR</div>
              <input
                type="text"
                id="exam-inp"
                value={exam}
                onChange={(e) => setExam(e.target.value)}
                placeholder="Enter Exam"
              />
            </div>
            {/* Input field for subexam */}
            <div>
              <input
                type="text"
                id="subexam"
                value={subexam}
                onChange={(e) => setSubexam(e.target.value)}
                placeholder="Enter Subexam"
              />
            </div>
            {/* Textarea for test */}
            <div>
              <textarea
                id="test"
                value={test}
                onChange={(e) => setTest(e.target.value)}
                placeholder="Enter Test Details"
              />
            </div>
            {/* Input fields for marks, level, time, and cost */}
            <div>
              <input
                type="text"
                id="marks"
                value={marks}
                onChange={(e) => setMarks(e.target.value)}
                placeholder="Enter Marks"
              />
            </div>
            <div>
              <input
                type="text"
                id="level"
                value={level}
                onChange={(e) => setLevel(e.target.value)}
                placeholder="Enter Level"
              />
            </div>
            <div>
              <input
                type="text"
                id="time"
                value={time}
                onChange={(e) => setTime(e.target.value)}
                placeholder="Enter Time"
              />
            </div>
            <div>
              <input
                type="text"
                id="cost"
                value={cost}
                onChange={(e) => setCost(e.target.value)}
                placeholder="Enter Cost"
              />
            </div>
            <button type="submit" id="subm">
              Upload Test
            </button>
          </form>
        )}
        {display === "1" && <Imp />}
      </div>
    );
  }
};

export default Admin;
