import React, { useEffect, useState } from "react";
import "./Blog.css";
import ReactLoading from "react-loading";
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import { FaXTwitter, FaYoutube } from "react-icons/fa6";
function Blog() {
  const [blog, setblog] = useState(null);
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const decodedData = JSON.parse(decodeURIComponent(searchParams.get("dt")));
    console.log(decodedData);
    setblog(decodedData);
  }, []);

  if (!blog) {
    return (
      <div className="bare">
        <ReactLoading type={"bars"} color={"black"} height={100} width={100} />
        <div className="ld">LOADING...</div>
      </div>
    );
  } else {
    return (
      <div className="blog_a">
        <div className="nav_a">
          <div></div>
          <div className="nav_aa">
            <FaPhone className="phone" />
            <div className="txt_p">Call us @ 7267946048</div>
            <div className="txt_p">Call us @ 8738946048</div>
            <a href="https://www.facebook.com/profile.php?id=61554641666707&mibextid=LQQJ4d">

            <FaFacebookF className="phone" />
            </a>
            {/* <FaTelegramPlane className="phone" />
            <FaTwitter className="phone" /> */}
            <a href="https://www.instagram.com/infosriasacademy6048?igsh=MXN0dHBwaDA2ZWRibA%3D%3D&utm_source=qr">
            <FaInstagram className="phone" />
            </a>
            <a href="https://youtube.com/@SHIVRADHYAIASACADEMY?si=GNcb4BUqKhx3TuA4">

            <FaYoutube className="phone" />
            </a>
          </div>
        </div>

        <div className="blog">
          <div className="blog_title">{blog.title}</div>
          <div className="blog_body">
            <div dangerouslySetInnerHTML={{ __html: blog.body }} />
          </div>
        </div>
        <div className="footer blog_f" id="fo">
          <div className="f_a">SHIV RADHYA IAS</div>
          <div className="f_b">
            We are Education, create your passion and inspiration. And hope
            success will come for your dream.<br></br>
            Please send email and get latest news.
          </div>
          <div className="f_c">
            <FaFacebookF className="f_c_icon" />
            <FaInstagram className="f_c_icon" />
            <FaTwitter className="f_c_icon" />
          </div>
          <div className="line_f"></div>
          <div className="f_bt">
            <div className="f_bt_ele">HOME</div>
            <div className="f_bt_ele">ABOUT</div>
            <div className="f_bt_ele">TERMS</div>
            <div className="f_bt_ele">POLICY</div>
            <div className="f_bt_ele">CONTACT</div>
          </div>
          <div className="bottom" style={{ color: "white" }}>
            © 2022 Parikshalay, Inc. All rights reserved.
          </div>
        </div>
      </div>
    );
  }
}

export default Blog;
