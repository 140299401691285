import React, { useEffect, useState } from 'react'

import "./Card.css";
import { FaArrowRightToBracket } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import Payment from '../payment/Payment';
import ReactLoading from 'react-loading';
function Card(props) {
  //console.log("---++",props);
  const [score, setScore] = useState(false)
  const navigate = useNavigate();
  const DR={
    exam:props.title
  }
  const encodedData = encodeURIComponent(JSON.stringify(DR));

  useEffect(() => {
    const cnt = props.answers.filter(item => item.exam === props.title).length;
    if (cnt >= 1) {
      setScore(true);
    }
  }, [props.answers, props.title]);
  
  if(!props.data){
    return     <div className="bare">
    <ReactLoading type={"bars"} color={"black"} height={100} width={100}/>
    <div className="ld">LOADING...</div>
    </div>
  ;  }else{
  return (
    <div className="cardM">
      <div className="cardM_h">{props.title}</div>
      <div className="cardM_b">
        <div className="cardM_bb">
          <div className="cardM_b_a">Q</div>
          <div className="cardM_b_b">{props.data.length} Questions</div>
        </div>
        <div className="cardM_bb">
          <div className="cardM_b_c">M</div>
          <div className="cardM_b_b">{props.marks} Marks</div>
        </div>
        <div className="cardM_bb">
          <div className="cardM_b_d">D</div>
          <div className="cardM_b_b">Difficulty Level: {props.level}</div>
        </div>
        <div className="cardM_bb">
          <div className="cardM_b_e">L</div>
          <div className="cardM_b_b">{props.length} Minutes</div>
        </div>
      </div>
      <div className="sn">
        <Payment amount={props.amount} exam={props.title} email={props.email} mode={props.mode} data={props.data} level={props.level} name={props.name} length={props.length} marks={props.marks}/>
        {/* {score &&
        <a href={`/analytics?dt=${encodedData}`} target={"_blank"} style={{textDecoration:"none",fontSize:"15px"}}><div>CHECK SCORE</div></a>} */}
        {score &&
        <div onClick={() => navigate(`/analytics?dt=${encodedData}`)} style={{textDecoration:"none",fontSize:"15px"}}><div>CHECK SCORE</div></div>
        }
        <div className="sn_icon">
            <FaArrowRightToBracket className="icon_sn"/>
        </div>
      </div>
    </div>
  );
}
}

export default Card;
