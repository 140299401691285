import React, { useEffect, useState } from "react";
import "./TS.css";
import { CgProfile } from "react-icons/cg";
import { GrInProgress } from "react-icons/gr";
import { CgTimelapse } from "react-icons/cg";

import { DATA } from "./data";
import analy from "../analytics/analy.png"
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import ReactLoading from 'react-loading';
import baseURL from "../../config/baseURl";
// import { set } from "mongoose";


function TS(props) {
const [name, setname] = useState(null)
const [data, setdata] = useState(null)
const [level, setlevel] = useState(null)
const [marks, setmarks] = useState(null)
const [title, settitle] = useState(null)
const [length, setlength] = useState(null)
const [email, setemail] = useState(null)
// Decode and retrieve the encoded data
const [answers, setanswers] = useState([]);
const [timer, setTimer] = useState(null);
const [ins, setins] = useState(false)


useEffect(() => {
  const searchParams = new URLSearchParams(window.location.search);
  const decodedData = JSON.parse(decodeURIComponent(searchParams.get("dt")));

  const NAME = decodedData.name;
  const DATA = decodedData.data;
  const LEVEL = decodedData.level;
  const TITLE = decodedData.title;
  const LENGTH = decodedData.length;
  const EMAIL = decodedData.email;
  const MARKS = decodedData.marks;

  setname(NAME);
  setdata(DATA);
  setlevel(LEVEL);
  settitle(TITLE);
  setlength(LENGTH);
  setTimer(LENGTH * 60); // Set timer in seconds
  setemail(EMAIL);
  setmarks(MARKS);

  console.log(decodedData);


  const timerInterval = setInterval(() => {
    setTimer((prevTimer) => {
      if (prevTimer <= 0) {
        clearInterval(timerInterval);
        return 0;
      }
      return prevTimer - 1;
    });
  }, 1000);

  return () => clearInterval(timerInterval);
}, []);


const formattedTime = () => {
  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;
  return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};


  // const name = props.name;
  const [selectedques, setselectedques] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };
  const saveQues = () => {
    const correctANS=data[selectedques].answer;
  
    if (selectedOption !== null) {
      // Check if there's already an entry for selectedques
      const existingIndex = answers.findIndex(answer => answer.selectedques === selectedques);
      if (existingIndex !== -1) {
        // Update selectedOption for the existing entry
        const updatedAnswers = [...answers];
        updatedAnswers[existingIndex].selectedOption = selectedOption;
        setanswers(updatedAnswers);
        setSelectedOption(null);
      } else {
        // Add new entry
        setanswers(prevAnswers => [
          ...prevAnswers,
          { selectedques, selectedOption,correctANS },
        ]);
        setSelectedOption(null);
      }
    }
    setselectedques((selectedques + 1)%data.length);
  };
  
  const handleSubmit=async()=>{
    
    try {
      // Make POST request to backend
      const response = await fetch(`${baseURL}/submit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          answers,
          email,
          exam:title
        }),
      });
  
      if (response.ok) {
        const data = await response.json();
       
        console.log(data); // Log response from server
        const DR={
          exam:title
        }
        const encodedData = encodeURIComponent(JSON.stringify(DR));

        window.location.href=`/analytics?dt=${encodedData}`;
        // Optionally, you can handle success response here
      } else {
        alert('Failed to Submit. Please try again.');
      }
    } catch (error) {
      alert(error);
      // Handle error here
    }
  }
  const manageQues = (i) => {
    const correctANS=data[selectedques].answer;
    const emp = "";
    setanswers((prevAnswers) => [
      ...prevAnswers,
      { selectedques, selectedOption: emp,correctANS },
    ]);
    setselectedques(i);
  };
  if(!data){
    return     <div className="bare">
      <ReactLoading type={"bars"} color={"black"} height={100} width={100}/>
      <div className="ld">LOADING...</div>
      </div>
    ;
  }else if(!ins){
    return (
      <>
        <div className="ts_a">
          <div className="ts_a_txt">SHIV RADHYA IAS</div>
          <div className="ts_a_icon">
            <div>Hi {name}!</div>
            <div className="ts_ic">
              <CgProfile className="icon" />
            </div>
          </div>
        </div>
        <div className="ana_a">
          <div className="ana_a_l">
            <div className="ana_a_l_h">{title} </div>
            <div className="ana_a_l_b">
              <div className="ana_a_l_b_ele">
                Total Questions: {data.length}
              </div>
              <div className="ana_a_l_b_ele">Total Marks: {marks}</div>
              <div className="ana_a_l_b_ele">
                Difficulty Level: {level}
              </div>
              <div className="ana_a_l_b_ele">Length: {length}</div>
            </div>
          </div>
          <div className="ana_a_r">
            <img src={analy} className="ana_a_img" />
          </div>
        </div>
        <div className="ins">
          <div className="ins_1">
            <div className="ei">Exam Instructions</div>
            <div className="ei_ni">
              <span className="note">Note* :</span> Please Read The Instructions
              Carefully.
            </div>
          </div>
          <div className="ins_2">
            <div className="ins_2_l rb">
              <div className="gi">General Instructions</div>
              <div className="ins_2_l_ele">
                <div className="gb">
                  <IoMdCheckmarkCircleOutline className="ii_icon" />
                  Total Time
                </div>
                <div className="ei_ni">
                  Total duration of examinatino is {length} minutes.
                </div>
              </div>

              <div className="ins_2_l_ele">
                <div className="gb">
                  <IoMdCheckmarkCircleOutline className="ii_icon" />
                  Exam Timer
                </div>
                <div className="ei_ni">
                  The clock has been set at the server and the countdown timer
                  at the top right corner of your screen will display the time
                  remaining for you to complete the exam. When the clock runs
                  out, the exam ends by defaults - you are not required to end
                  or submit your exam.
                </div>
              </div>
              <div className="ins_2_l_ele">
                <div className="gb">
                  <IoMdCheckmarkCircleOutline className="ii_icon" /> The
                  question palette at the right of screen shows one of the
                  following statuses of each of the questions numbered:
                </div>
                <ol type="A" className="lo">
                  <li className="lo_ele">
                    <span className="lo_ele_1">01</span>You have not answered
                    the question yet.
                  </li>
                  <li className="lo_ele">
                    <span className="lo_ele_2">01</span>You have answered the
                    question.
                  </li>

                  <li className="lo_ele">
                    <span className="lo_ele_3">01</span>You have not visited the
                    question yet.
                  </li>
                </ol>
                <div className="ei_ni">
                  The Marked for Review Status simply acts as a reminder that
                  you have set to look at the question again.
                </div>
              </div>
            </div>
            <div className="ins_2_l">
              <div className="gi">Navigating to a question</div>

              <div className="ins_2_l_ele">
                <div className="gb">
                  <IoMdCheckmarkCircleOutline className="ii_icon" /> The To
                  Select a question to answer, you can do one of following:
                </div>
                <ol type="A" className="lo">
                  <li className="lo_ele">
                    Click on the question number on the question palette at the
                    right of your screen to go to that numbered question
                    direction your answer to the current question.
                  </li>
                  <li className="lo_ele">
                    Click on Save and Next to save answer to current question
                    and to go to the next in sequence.
                  </li>
                  <li className="lo_ele">
                    Click on Mark for Review and Next to save answer to current
                    question, mark it for review, and to go to the next
                    question.
                  </li>
                </ol>
              </div>
              <div className="ins_2_l_ele">
                <div className="gb">
                  <IoMdCheckmarkCircleOutline className="ii_icon" />
                  Question Paper{" "}
                </div>
                <div className="ei_ni">
                  You can view the entire paper by clicking on the Question
                  Paper Button.
                </div>
              </div>
            </div>
          </div>
          <div className="ins_3">
            <div className="gi">Answering questions</div>

            <div className="ins_2_l_ele">
              <div className="gb">
                <IoMdCheckmarkCircleOutline className="ii_icon" /> For multiple
                choice type question
              </div>
              <ol type="A" className="lo">
                <li className="lo_ele">
                  To Select a question to answer, you can do one of following:
                </li>
                <li className="lo_ele">
                  To change your answer, click the another desired option
                  button.
                </li>
                <li className="lo_ele">
                  To save your answer, you MUST click on Save and Next.
                </li>
                <li className="lo_ele">
                  To deselect a chosen answer, click on the chosen option again
                  or click on the Clear Response button.
                </li>
                <li className="lo_ele">
                  To mark a question for review click on Mark For Review & Next.
                </li>
              </ol>
            </div>
            <div className="ins_2_l_ele">
              <div className="gb">
                <IoMdCheckmarkCircleOutline className="ii_icon" /> The For a
                numerical answer type question:
              </div>
              <ol type="A" className="lo">
                <li className="lo_ele">
                  To enter a number as your answer, use the virtual numerical
                  keypad.
                </li>
                <li className="lo_ele">
                  A fraction (eg. 0.3 ot -0.3) can be entered as an answer ONLY
                  with ‘0’ before the decimal point.{" "}
                </li>
                <li className="lo_ele">
                  To save your answer, you MUST click on Save and Next.
                </li>
                <li className="lo_ele">
                  To clear you answer, clcik on the Clear Response button.
                </li>
              </ol>
            </div>
            <div className="ins_2_l_ele">
              <div className="gb">
                <IoMdCheckmarkCircleOutline className="ii_icon" />
                To change an answer to a question, first select the question and
                then click on the new answer option followed by a click on the
                Save & Next button.
              </div>
            </div>
            <div className="ins_2_l_ele">
              <div className="gb">
                <IoMdCheckmarkCircleOutline className="ii_icon" />
                Question that are saved or marked for review after answering
                will ONLY be considered for evaluation.
              </div>
            </div>
          </div>
        </div>
        {/* <div className="rbn" onClick={()=>setins(true)} style={{cursor:"pointer"}}>I AM READY</div> */}
        <button className=" bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full
        " onClick={()=>setins(true)}>Start</button>
      </>
    );
  }else{
  return (
    <div className="ts">
      <div className="ts_a">
        <div className="ts_a_txt">SHIV RADHYA IAS</div>
        <div className="ts_a_icon">
          <div>Hi {name}!</div>
          <div className="ts_ic">
            <CgProfile className="icon" />
          </div>
        </div>
      </div>
      <div className="ts_b">
        <div className="ts_b_txt">{title}</div>
        <div className="ts_b_txt_2">
          <GrInProgress className="dl_icon" />
          <div className="ts_b_txt_2_a">Difficulty Level: {level}</div>
        </div>
      </div>
      <div className="qna">
        <div className="qna_l">
          <div>
            <div className="ques">
              <div className="in">Q{selectedques + 1}.</div>
              <div className="q">{data[selectedques].question}</div>
            </div>

            <div className="options">
              {data[selectedques].options.map((option, index) => {
                const optionKey = `option${index}`;
                const isChecked = answers.some(
                  (answer) => answer[0] === selectedques && answer[1] === option
                );
                return (
                  <div key={index} className="opt">
                    <label htmlFor={`option${index}`} className="af">
                      <div
                        className={`${
                          selectedOption === option ? "selected" : "gr"
                        }`}
                      >
                        {String.fromCharCode(65 + index)}
                      </div>{" "}
                     <div>{option}</div> 
                    </label>
                    <input
                      type="radio"
                      id={optionKey}
                      name="option"
                      value={option}
                      checked={selectedOption === option || isChecked}
                      onChange={() => handleOptionSelect(option)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="qna_bot">
            <div className="qna_bot_a">
              <div>
                <CgTimelapse className="timer_ic" />
              </div>

              <div style={{textAlign:"left",marginTop:"10px"}}>  Time Elapsed on this question : {formattedTime()} </div>
            </div>
            <div
              className="qna_bot_btn"
              onClick={saveQues}
              style={{ cursor: "pointer" }}
            >
              SAVE AND NEXT
            </div>
          </div>
        </div>
        <div className="qna_r">
          <div className="qna_r_h">Questions</div>

          <div className="qna_r_b ">
            {data.map((e, index) => {
              // Check if index+1 is in the first element of subarrays in answers
              const hasAnswer = answers.some(
                (answer) => answer.selectedques === index
              );
              // Check if the second element is not an empty string
              const hasSelectedOption = answers.some(
                (answer) =>
                  answer.selectedques === index && answer.selectedOption !== ""
              );

              const handleClick = () => {
                manageQues(index);
              };

              return (
                <div
                  className={`qna_bubble ${
                    hasAnswer && hasSelectedOption
                      ? "purple"
                      : hasAnswer
                      ? "orange"
                      : ""
                  }`}
                  key={index}
                  onClick={handleClick}
                >
                  {/* Display the count with leading zeros */}
                  {`${(index + 1).toString().padStart(2, "0")} `}
                  {/* Your other content */}
                </div>
              );
            })}
          </div>

          <div className="sbt_btn" onClick={handleSubmit} style={{cursor:"pointer"}}>SUBMIT EXAM</div>
        </div>
      </div>
    </div>
  );
}
}
export default TS;
