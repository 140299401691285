import React, { useState, useEffect, useRef } from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import apiClient from '../../config/AxiosConfig';
import { FaEllipsisV } from 'react-icons/fa';
import logo from '../home/images/sri.png';
import {jwtDecode} from 'jwt-decode';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Skeleton from '@mui/joy/Skeleton';

export default function CourseBox({ courseData }) {
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [userID, setUserID] = useState(null);
  const [userType, setUserType] = useState(null); // ['student', 'teacher', 'admin']
  const navigate = useNavigate();
  const alertShownRef = useRef(false);
  const user = jwtDecode(localStorage.getItem('jwtToken'));
  const [loading, setLoading] = useState(true);

  function BasicSkeleton() {
    return (
      <Card variant="outlined" sx={{ width: 320,height:360, display: 'flex', gap: 2 }}>
        <AspectRatio ratio="21/9">
          <Skeleton variant="overlay">
            <img
              alt=""
              src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
            />
          </Skeleton>
        </AspectRatio>
        <Typography>
          <Skeleton>
            <Typography variant="h6">Loading Course ...</Typography>
          </Skeleton>
        </Typography>
      </Card>
    );
  }
  useEffect(() => {
    const fetchUserID = async () => {
      try {
        const response = await apiClient.get('/findUserID');
        setUserID(response.data.user._id);
        setUserType(response.data.user.role);
        //console.log(response.data.user._id);
      } catch (error) {
        console.error(error);
        if (!alertShownRef.current) {
          alert('An error occurred while fetching user data.');
          alertShownRef.current = true;
          navigate('/');
        }
      }
    };

    if (!userID) {
      setLoading(true);
      fetchUserID();
    }
    else {
      if (courseData.Students.includes(userID) || courseData.Instructors.includes(userID) ) {
        setIsEnrolled(true);
      }
      setLoading(false);
    }

  }, [userID]); // Fetch user ID only when it's not already set

  const handleEnroll = async () => {
    if(userType==="teacher" || userType==="admin"){
      try {
        const response = await apiClient.post('/enrollCourse', { code: courseData.code });
        //console.log(response.data);
        window.location.reload();
      } catch (error) {
        console.error(error);
        alert('An error occurred while joining the course.');
      }
    }
    else if(courseData.course_price===0){
      try {
        const response = await apiClient.post('/enrollFreeCourse', { code: courseData.code });
        //console.log(response.data);
        window.location.reload();
      } catch (error) {
        console.error(error);
        alert('An error occurred while joining the course.');
      }
    }
    else{
      try {
        const orderUrl = '/create-order-course';
        const { data } = await apiClient.post(orderUrl, { courseCode: courseData.code });
  
        const options = {
          key: "rzp_test_otYNEG73MSiVBf", // Key ID generated from the Dashboard
          amount: data.amount,
          currency: data.currency,
          name: 'SHIV RADHYA IAS',
          description: `Payment for ${courseData.code}`,
          image: { logo },
          order_id: data.id,
          handler: async function (response) {
            //alert(`Payment successful! Payment ID: ${response.razorpay_payment_id}`);
            toast.info('Processing payment, please wait...');
            try {
              const paymentResult = await apiClient.post('/verify-payment', {
                order_id: data.id,
                payment_id: response.razorpay_payment_id,
                signature: response.razorpay_signature,
                courseCode: courseData.code,
              });
              toast.success('Payment successful! You have been enrolled in the course.');
              setTimeout(() => {
                window.location.reload();
              }, 5000);
              
            } catch (error) {
              toast.error('Could not verify payment, in case of any issue please contact support, your money will be refunded within 7 working days.');
              setTimeout(() => {
                window.location.reload();
              }, 5000);
            }
          },
          prefill: {
            name: user.name,
            email: user.email,
            contact: user.mobile,
          },
          retry: {
            enabled: false,
          },
          timeout: 900, // Time limit in Seconds
          theme: {
            color: '#3399cc',
          },
        };
  
        const rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', function (response) {
          toast.error(`Payment failed! ${response.error.description}`);
          setTimeout(() => {
            window.location.reload();
          }, 10000);
        });
  
        rzp1.open();
      } catch (error) {
        console.error('Error creating order:', error);
        toast.error('An error occurred while processing the payment. Please try again later.');
        setTimeout(() => {
          window.location.reload();
        }, 10000);
        //window.location.reload();
      }
  }
  };


  const truncateText = (text, maxLength) => {
    if (text.length === 0) return '...';
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  if (loading) {
    return <BasicSkeleton />;
  }

  return (
    <Card sx={{ width: 320, position: 'relative' }}>
      <ToastContainer />
      <div>
        <Typography level="title-lg" sx={{ fontWeight: 'bold' }}>{courseData.title}</Typography>
        <Typography level="body-sm" sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 3,
          WebkitBoxOrient: 'vertical'
        }}>
          {truncateText(courseData.description, 100)}
        </Typography>
        {userType==="teacher" || userType==="admin"?
        <IconButton
          size="large"
          variant="outlined"
          color='neutral'
          sx={{ position: 'absolute', top: '0.575rem', right: '0.5rem',padding: '6px'  }}
        >
          <FaEllipsisV/>
        </IconButton>:null}
      </div>
      <AspectRatio minHeight="120px" maxHeight="200px">
        <img
          src={courseData.display_picture}
          loading="lazy"
          alt="Course"
        />
      </AspectRatio>
      <CardContent orientation="horizontal">
        <div>
          <Typography level="body-xs">Start Date:</Typography>
          <Typography fontSize="sm" fontWeight="lg">
            {dayjs(courseData.start_date).format('DD MMM YYYY')}
          </Typography>
        </div>
        <div>
          <Typography level="body-xs">Status:</Typography>
          <Typography fontSize="sm" fontWeight="lg">
            {courseData.status}
          </Typography>
        </div>
        <div>
          <Typography level="body-xs">Mode:</Typography>
          <Typography fontSize="sm" fontWeight="lg">
            {courseData.mode}
          </Typography>
        </div>
        <div>
          <Typography level="body-xs">Language:</Typography>
          <Typography fontSize="sm" fontWeight="lg">
            {courseData.language}
          </Typography>
        </div>
      </CardContent>
      <CardContent orientation="horizontal">
        <div>
          <Typography level="body-xs">Course price:</Typography>
          <Typography fontSize="lg" fontWeight="lg">
            {courseData.course_price===0?"Free":`₹ ${courseData.course_price}`}
          </Typography>
        </div>
        {isEnrolled?<Button
          variant="solid"
          size="md"
          color="primary"
          aria-label="View"
          sx={{ ml: 'auto', alignSelf: 'center', fontWeight: 600 }}
          onClick={() => navigate(`/classpage/${courseData.code}`)}
        >
          {userType==="teacher" || userType==="admin"?"Manage":"View"}
        </Button>:<Button variant="solid" size="md"
          color="neutral"
          aria-label="Enroll"
          sx={{ ml: 'auto', alignSelf: 'center', fontWeight: 600 }}
          onClick={handleEnroll}
        >
          {userType==="teacher" || userType==="admin"?"Join":"Enroll"}
        </Button>
        }
      </CardContent>
    </Card>
  );
}
