

import React, { useEffect, useState } from 'react';
import {jwtDecode} from 'jwt-decode';
import apiClient from '../../../config/AxiosConfig';
import { CircularProgress } from '@mui/material';
import SideBar from './SideBar';
export default function HostView({room_name}) {
    const [roomName, setRoomName] = useState('');
    const [ingress, setIngress] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setRoomName(room_name);
        //getIngress from server
        const fetchIngress = async () => {
            try {
                const response = await apiClient.post('/create-live-stream-ingress', {
                    code: room_name
                });
                setIngress(response.data.ingressUrl);
                console.log("Ingress: ", response.data.ingressUrl);
                setLoading(false);
            } catch (error) {
                console.log("Error: ", error);
                setError(true);
                setLoading(false);
            }
        };
        fetchIngress();
    }, []);
    if(loading){
        return (
            <CircularProgress />
        );
    }
    else if(error){
        return (
            <div>
                Something went wrong!, Please try again!
            </div>
        );
    }
    
    return (
        <SideBar roomName={roomName} ingress={ingress} />
    );
    }
