import React, { useState, useEffect } from "react";
import sri from '../home/images/sri.png';
import { Link } from "react-router-dom";
import handleGoogleSignIn from "../register/handleSigninGoogle";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Authentication/AuthContext";
import { createUserWithEmailAndPassword,sendEmailVerification, updateProfile} from "firebase/auth";
import { auth } from "../../config/firebaseConfig";
import apiClient from "../../config/AxiosConfig";

export default function Signup() {
  const navigate = useNavigate();
  const {signOut} = useAuth();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile_number: "",
    password: ""
  });
  
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    mobile_number: "",
    password: ""
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isErrorSignup, setIsErrorSignup] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  const handlePhoneChange = (e) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/\D/g, '');
    setFormData({
      ...formData,
      [name]: numericValue
    });
  };


  const validate = () => {
    let valid = true;
    let errors = {};

    if (!formData.name) {
      errors.name = "Full name is required";
      valid = false;
    }
    else if(formData.name.length < 2){
        errors.name = "Name must be at least 2 characters";
        valid = false;
    }
    else if(formData.name.length > 50){
        errors.name = "Name must be less than 50 characters";
        valid = false;
    }   
    else if (!/^[a-zA-Z ]+$/.test(formData.name)) {
        errors.name = "Name must contain only alphabets";
        valid = false;
    }
    

    if (!formData.email) {
      errors.email = "Email address is required";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid";
      valid = false;
    }

    if (!formData.mobile_number) {
      errors.mobile_number = "Mobile number is required";
      valid = false;
    } else if (!/^\d{10}$/.test(formData.mobile_number)) {
      errors.mobile_number = "Mobile number is invalid";
      valid = false;
    }

    if (!formData.password) {
      errors.password = "Password is required";
      valid = false;
    } else if (formData.password.length < 8) {
      errors.password = "Password must be at least 8 characters";
      valid = false;
    }
    else if (formData.password.length > 50) {
        errors.password = "Password is too long";
        valid = false;
    }
    else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,50}$/.test(formData.password)) {
        errors.password = "Password must contain at least one uppercase letter, one lowercase letter, one number and one special character";
        valid = false;
    }
    else if (formData.password.includes(" ")) {
        errors.password = "Password must not contain spaces";
        valid = false;
    }
    // else if (formData.password.includes(formData.name)) {
    //     errors.password = "Password must not contain name";
    //     valid = false;
    // }
    // else if (formData.password.includes(formData.email)) {
    //     errors.password = "Password must not contain email";
    //     valid = false;
    // }
    // else if (formData.password.includes(formData.mobile_number)) {
    //     errors.password = "Password must not contain mobile number";
    //     valid = false;
    // }

    setErrors(errors);
    return valid;
  };
  
  //sign out user if already signed in
  useEffect(() => {
    signOut();
  }, []);

  const handleSubmit = async(e) => {
    e.preventDefault();
    if (validate()) {
      setIsSubmitting(true);
      console.log("Creating user ...");
      try {
        const { user } = await createUserWithEmailAndPassword(
          auth,
          formData.email,
          formData.password
        );

        // Update profile with name and mobile
        await updateProfile(user, {
          displayName: formData.name
        });
        //api call to update mobile number and name
        try {
          await apiClient.post('/update-profile', {
            name: formData.name,
            mobile: formData.mobile_number
          });
        } catch (error) {
          console.error("Error updating profile:", error);
        }
        console.log("User created:", user);
        alert("Sign up successful!");
        navigate("/login"); // Redirect to login page after successful sign-up
      } catch (error) {
        handleFirebaseError(error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };
  const handleFirebaseError = (error) => {
    console.error("Firebase sign up failed", error);
    if (error.code === "auth/email-already-in-use") {
      alert("Email already in use. Please sign in or use a different email.");
    } else {
      alert("Sign up failed. Please try again.");
    }
  };

  const handleGoogle = async () => {
    setIsSubmitting(true);
    try {
      await handleGoogleSignIn();
      console.log("Google sign in successful");
      navigate("/panel", { replace: true });
    } catch (error) {
      console.error("Google sign in failed", error);
      alert("Google sign in failed. Please try again.");
      setIsSubmitting(false);
    }
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="max-w-md w-full bg-white shadow-md rounded-lg p-8 sm:p-10">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-10 w-auto"
            src={sri}
            alt="Shiv Radhya IAS"
          />
          <h2 className="mt-0 mb-2 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Create your account
          </h2>
        </div>

        <form className="space-y-6" onSubmit={handleSubmit} noValidate>
          <div>
            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-700 text-left">
              Full Name
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className={`absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none ${errors.name ? "mt-1" : ""}`}>
                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
                </svg>
              </div>
              <input
                id="fname"
                name="name"
                type="text"
                autoComplete="name"
                placeholder="John Doe"
                required
                value={formData.name}
                onChange={handleChange}
                className={`block w-full appearance-none rounded-md border px-3 py-2 ps-10 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm font-medium ${errors.name ? "border-red-500" : "border-gray-300"}`}
              />
              
            </div>
            {errors.name && <p className="text-red-500 text-xs mt-0">{errors.name}</p>}
          </div>
          
          <div className="">
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-700 text-left">
              Email address
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
                  <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z"/>
                  <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z"/>
                </svg>
              </div>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                placeholder="xyz@gmail.com"
                required
                value={formData.email}
                onChange={handleChange}
                className={`block w-full appearance-none rounded-md border px-3 py-2 ps-10 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm font-medium ${errors.email ? "border-red-500" : "border-gray-300"}`}
              />
              
            </div>
            {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
          </div>
          <div>
            <label htmlFor="mobile_number" className="block text-sm font-medium leading-6 text-gray-700 text-left">
              Mobile
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none justify-center">
                <span className="text-gray-500 sm:text-sm font-semibold">+91</span>
              </div>
              <input
                id="mobile_number"
                name="mobile_number"
                type="tel"
                autoComplete="mobile"
                placeholder="00000 00000"
                required
                value={formData.mobile_number}
                onChange={handlePhoneChange}
                className={`block w-full appearance-none rounded-md border px-3 py-2 ps-10 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm font-medium ${errors.mobile_number ? "border-red-500" : "border-gray-300"}`}
              />

            </div>
            {errors.mobile_number && <p className="text-red-500 text-xs mt-1">{errors.mobile_number}</p>}
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-700 text-left">
              Password
            </label>
            <div className="mt-1">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                value={formData.password}
                onChange={handleChange}
                className={`block w-full appearance-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm font-medium ${errors.password ? "border-red-500" : "border-gray-300"}`}
              />
              
            </div>
            {errors.password && <p className="text-red-500 text-xs mt-1">{errors.password}</p>}
          </div>

          <button
            type="submit"
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Signing up..." : "Sign Up"}
          </button>
        </form>

        <div className="mt-6 relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-600" />
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white text-gray-1000 font-semibold">
              Or continue with
            </span>
          </div>
        </div>

        <div className="mt-6 grid grid-cols-1 gap-3">
          <div className="w-full inline-flex justify-center">
            <button
              type="button"
              className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-indigo-50 hover:text-gray-900 hover:border-indigo-600 disabled:opacity-50 disabled:hover:bg-white disabled:hover:text-gray-500 disabled:hover:border-gray-300"
              onClick={handleGoogle}
              disabled={isSubmitting}
            >
              <svg className="w-5 h-5" aria-hidden="true" style={{ display: 'block' }} viewBox="0 0 48 48">
                <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                <path fill="none" d="M0 0h48v48H0z"></path>
              </svg>
              <span className="ml-2">{isSubmitting ? "Signing up..." : "Sign Up with Google"}</span>
            </button>
          </div>
        </div>

        <p className="mt-6 text-center text-sm font-medium text-gray-700">
          Already have an account? <Link to="/login" className="font-medium text-indigo-700 hover:underline hover:text-indigo-500">Sign In</Link>
        </p>
      </div>
    </div>
  );
}
