import React, { useState, useEffect, useRef } from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import apiClient from '../../config/AxiosConfig';
import { FaEllipsisV } from 'react-icons/fa';

export default function CourseBox({courseData}) {
  const [isEnrolled, setIsEnrolled] = useState(false);
  //const [userID, setUserID] = useState(null);
  //const [userType, setUserType] = useState(null); // ['student', 'teacher', 'admin']
  const navigate = useNavigate();
  const alertShownRef = useRef(false);

  
  const handleEnroll = async () => {
    try {
      const response = await apiClient.post('/enrollCourse', { code: courseData.code });
      // console.log(response.data);
      setIsEnrolled(true);
    } catch (error) {
      console.error(error);
      alert('An error occurred while enrolling in the course.');
    }
  };

  const truncateText = (text, maxLength) => {
    if (text.length === 0) return '...';
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  return (
    <Card sx={{ width: 320, position: 'relative' }}>
      <div>
        <Typography level="title-lg" sx={{ fontWeight: 'bold' }}>{courseData.title}</Typography>
        <Typography level="body-sm" sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 3,
          WebkitBoxOrient: 'vertical'
        }}>
          {truncateText(courseData.description, 100)}
        </Typography>
        {/* {userType==="teacher" || userType==="admin"?
        <IconButton
          size="large"
          variant="outlined"
          color='neutral'
          sx={{ position: 'absolute', top: '0.575rem', right: '0.5rem',padding: '6px'  }}
        >
          <FaEllipsisV/>
        </IconButton>:null} */}
      </div>
      <AspectRatio minHeight="120px" maxHeight="200px">
        <img
          src={courseData.display_picture}
          loading="lazy"
          alt="Course"
        />
      </AspectRatio>
      <CardContent orientation="horizontal">
        <div>
          <Typography level="body-xs">Start Date:</Typography>
          <Typography fontSize="sm" fontWeight="lg">
            {dayjs(courseData.start_date).format('DD MMM YYYY')}
          </Typography>
        </div>
        <div>
          <Typography level="body-xs">Status:</Typography>
          <Typography fontSize="sm" fontWeight="lg">
            {courseData.status}
          </Typography>
        </div>
        <div>
          <Typography level="body-xs">Mode:</Typography>
          <Typography fontSize="sm" fontWeight="lg">
            {courseData.mode}
          </Typography>
        </div>
        <div>
          <Typography level="body-xs">Language:</Typography>
          <Typography fontSize="sm" fontWeight="lg">
            {courseData.language}
          </Typography>
        </div>
      </CardContent>
      <CardContent orientation="horizontal">
        <div>
          <Typography level="body-xs">Course price:</Typography>
          <Typography fontSize="lg" fontWeight="lg">
          {courseData.course_price===0?"Free":`₹ ${courseData.course_price}`}
          </Typography>
        </div>
        {/* isEnrolled?<Button
          variant="solid"
          size="md"
          color="primary"
          aria-label="View"
          sx={{ ml: 'auto', alignSelf: 'center', fontWeight: 600 }}
          onClick={() => navigate(`/classpage/${courseData.code}`)}
        >
          {userType==="teacher" || userType==="admin"?"Manage":"View"}
        </Button>:<Button variant="solid" size="md"
          color="neutral"
          aria-label="Enroll"
          sx={{ ml: 'auto', alignSelf: 'center', fontWeight: 600 }}
          onClick={handleEnroll}
        >
          {userType==="teacher" || userType==="admin"?"Join":"Enroll"}
        </Button> */
        }
      </CardContent>
    </Card>
  );
}
