import React, { useState, useEffect } from "react";
import { HiMenuAlt3 } from "react-icons/hi";
import { MdOutlineDashboard } from "react-icons/md";
import { RiSettings4Line } from "react-icons/ri";
import { MdKey} from "react-icons/md";
import { Link, Navigate } from "react-router-dom";
import {Fullscreen, SidebarOpen, SidebarClose} from "lucide-react";
import { School , CheckCheck, Copy} from "lucide-react";
import {Button, IconButton} from "@mui/joy";
import  apiClient from "../../../config/AxiosConfig";
import CustomChat from "./CustomChat";
import {
  LiveKitRoom,
  VideoConference,
  formatChatMessageLinks,
  useToken,
  useConnectionState,
  useRemoteParticipant,
  useTracks,
  LayoutContextProvider,
  Chat,
  ChatToggle,
  ChatIcon,
  


} from '@livekit/components-react';
import {
  Room,
  RoomConnectOptions,
  RoomOptions,
  VideoCodec,
  VideoPresets,
  
} from 'livekit-client';
import VideoFeed from "./VideoFeed";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const SideBar = ({ingress, roomName}) => {
  const menus = [
    { name: "Stream", link: "/", icon: Fullscreen },
    { name: "Keys", link: "/", icon: MdKey },
    { name: "Settings", link: "/", icon: RiSettings4Line },
    { name: "Dashboard", link: "/panel", icon: MdOutlineDashboard, margin: true },
    { name: "Classroom", link: `/classpage/${roomName}`, icon: School }
  ];
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const [open, setOpen] = useState(isLargeScreen);
  const [urlCopied, setUrlCopied] = useState(false);
  const [keyCopied, setKeyCopied] = useState(false);
  const [active, setActive] = useState(1);
  const liveKitUrl = "wss://livekit.shivradhyaias.com";
  const [livekitToken, setLivekitToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showChat, setShowChat]= useState(false);
  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await apiClient.get('/getTokenLiveStream', {
          params: {
            roomName: roomName
          }
        });
        setLivekitToken(response.data.token);
        setLoading(false);
      } catch (error) {
        console.log("Error: ", error);
        setLoading(false);
      }
    };
    fetchToken();
  }, [roomName]);
  const handleActive = (i) => { setActive(i); };
  const onCopyUrl = () => {
    setUrlCopied(true);
    setTimeout(() => setUrlCopied(false), 1000);
  };

  const onCopyKey = () => {
    setKeyCopied(true);
    setTimeout(() => setKeyCopied(false), 1000);
  };

  return (
    <section className="flex gap-1 bg-gray-200">
      <div
        className={`bg-[#0e0e0e] min-h-screen ${
          open ? "w-72" : "w-16 pr-0.5"
        } duration-500 text-gray-100 px-4`}
      >
        <div className="py-3 flex justify-end">
          {open?<SidebarClose
            size={26}
            className="cursor-pointer"
            onClick={() => setOpen(!open)}
          />:<SidebarOpen
            size={26}
            className="cursor-pointer"
            onClick={() => setOpen(!open)}
          />}
        </div>
        <div className="mt-4 flex flex-col gap-4 relative">
          {menus?.map((menu, i) => (
            <div
              onClick={() => handleActive(i)}
              key={i}
              className={` ${
                menu?.margin && "mt-20"
              } group flex items-center text-sm  gap-3.5 font-medium p-2 hover:bg-gray-800 rounded-md`}
            >
              <div>{React.createElement(menu?.icon, { size: "20" })}</div>
              <h2
                style={{
                  transitionDelay: `${i + 3}00ms`,
                }}
                className={`whitespace-pre duration-500 ${
                  !open && "opacity-0 translate-x-28 overflow-hidden z-10"
                }`}
              >
                {menu?.name}
              </h2>
              <h2
                className={`${
                  open && "hidden"
                } absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
              >
                {menu?.name}
              </h2>
            </div>
          ))}
        </div>
      </div>
      <div className="m-3 ml-0 text-xl text-gray-900 font-semibold w-full ">
        {active===0 && (loading ? (<div className="p-6 font-bold">
          Can't Watch Stream Now. Please Try Again.
        </div>): (<div className="p-6 md:pd-3 sm:pd-2 xs:pd-1 -z-10">
          <LayoutContextProvider>
            <LiveKitRoom
              token={livekitToken}
              serverUrl={liveKitUrl}
              // data-lk-theme="default"
              className="grid grid-cols-1 lg:gap-y-0  h-full"
            >
            {/* lg:grid-cols-3 
            xl:grid-cols-3 2xl:grid-cols-6 */}
             {/* lg:col-span-2 xl:col-span-2 2xl:col-span-5 */}
              <div className="space-y-4 col-span-1
              
              lg:overflow-y-auto hidden-scrollbar">
                <VideoFeed roomName={roomName} />
              </div>
              <div className="col-span-1 lg:col-span-1 xl:col-span-1 2xl:col-span-1 mt-2">
                <CustomChat participantName="Host" />
              </div>

              {/* <div className=" overflow-scroll
              "
              >
              <Chat data-lk-theme="default" className="data-lk h-full" messageFormatter={formatChatMessageLinks}
                
              />
              </div> */}
              {/* <div className='absolute top-0 h-full w-full opacity-0 hover:opacity-100 transition-all'>
            <div className='absolute bottom-0 flex h-14 w-full items-center justify-between bg-gradient-to-r from-neutral-900 px-4'>
                <button className='bg-slate-900 text-white rounded-md p-2'>Fullscreen</button>
            </div>
        </div> */}


            </LiveKitRoom>
          </LayoutContextProvider>
          </div>))
        }
        {active===1 && <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-2xl font-bold">
              Keys & URLs
            </h1>    
          </div>
          <div className="space-y-4">
            <div className="rounded-xl bg-zinc-300 p-6">
              <div className="flex items-start gap-x-10 content-center w-full">
                <p className="font-semibold shrink-0 ">
                  Server URL
                </p>
                <div className="space-y-2 w-full">
                  <div className="w-full flex items-center gap-x-1">
                    <input
                      name="streamURL"
                      type="text"
                      placeholder="Stream Server URL"
                      disabled
                      value={ingress.url}
                      className={`w-full appearance-none rounded-md border border-slate-600 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm font-medium `}
                    />
                    <Button onClick={() => {
                      onCopyUrl();
                      void navigator.clipboard.writeText(ingress?.url ?? "");
                    }} variant="contained" color="primary" className="h-10">
                      {urlCopied ? <IconButton><CheckCheck size={20} /></IconButton> : <IconButton><Copy size={20} /></IconButton>}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="space-y-4 mt-4">
            <div className="rounded-xl bg-zinc-300 p-6">
              <div className="flex items-start gap-x-10 content-center w-full">
                <p className="font-semibold shrink-0 ">
                  Stream Key
                </p>
                <div className="space-y-2 w-auto">
                  <div className="w-full flex items-center gap-x-1">
                    <input
                      name="streamKey"
                      type="text"
                      placeholder="Stream Server Key"
                      disabled
                      value={ingress.streamKey}
                      className={`w-full appearance-none rounded-md border border-slate-600 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm font-medium `}
                    />
                    <Button onClick={() => {
                      onCopyKey();
                      void navigator.clipboard.writeText(ingress?.streamKey ?? "");
                    }} variant="contained" color="primary" className="h-10">
                      {keyCopied ? <IconButton><CheckCheck size={20} /></IconButton> : <IconButton><Copy size={20} /></IconButton>}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Setup instructions */}
          <div className="mt-4">
            <h1 className="text-2xl font-bold">Setup Instructions</h1>
            <div className="space-y-4 mt-4">
              <div className="rounded-xl bg-zinc-300 p-6">
                <div className="flex items-start gap-x-10 content-center w-full">
                  <p className="font-semibold shrink-0">OBS Setup</p>
                  <div className="space-y-2 w-full">
                    <div className="w-full flex flex-col items-start gap-y-1">
                      <p className="text-gray-600">1. Open OBS Studio</p>
                      <p className="text-gray-600">2. Click on Settings</p>
                      <p className="text-gray-600">3. Go to Stream</p>
                      <p className="text-gray-600">4. Select Custom Service</p>
                      <p className="text-gray-600">5. Enter the Server and Stream Key</p>
                      <p className="text-gray-600">6. Click on Start Streaming</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ready to go */}
          <div className="mt-4">
            <h1 className="text-2xl font-bold">Ready to Go</h1>
            <div className="space-y-4 mt-4">
              <div className="rounded-xl bg-zinc-300 p-6">
                <div className="flex items-start gap-x-10 content-center w-full">
                  <p className="font-semibold shrink-0">You are ready to go live!</p>
                  <button onClick={() => handleActive(0)} className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  View Stream</button>
                </div>
              </div>
            </div>
          </div>
        </div>}
        {active===3 && <Navigate to={menus[active]?.link} />}
        {active===4 && <Navigate to={menus[active]?.link} />}
      </div>
    </section>
  );
};

export default SideBar;