import React, { useState, useCallback, useMemo, KeyboardEvent } from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { ChatIcon, useChat } from "@livekit/components-react";
import { MessageSquareText, SendIcon } from 'lucide-react';

const Chat = ({ participantName, messages, send, message, setMessage, onEnter, onSend }) => {
  const sortedMessages = useMemo(
    () => [...messages].sort((a, b) => a.timestamp - b.timestamp),
    [messages]
  );
  const [isTeacher, setIsTeacher] = useState(false);

  return (
    <div className="flex flex-col h-full">
      <div className="flex-1 overflow-y-auto p-4">
        {sortedMessages.map((msg) => (
          <div key={msg.timestamp} className="mb-2 flex items-start gap-2">
            <div className="flex-shrink-0 text-xs font-semibold">
              <div className={msg.from.identity.startsWith("teacher") ? "text-indigo-500" : "text-gray-700"}>
                {msg.from.name}
                {msg.from.identity.startsWith("teacher") && " (Host)"}
              </div>
              <div className="text-xs text-gray-500">
                {new Date(msg.timestamp).toLocaleTimeString()}
              </div>
            </div>
            <div className="text-sm bg-zinc-300 rounded p-2">
              {msg.message}
            </div>
          </div>
        ))}
      </div>
      <div className="flex p-2 border-t border-gray-200 dark:border-gray-700">
        <TextareaAutosize
          value={message}
          className="flex-1 bg-zinc-400 p-2 placeholder:text-white border rounded ring-1 ring-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:bg-zinc-300 font-semibold"
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={onEnter}
          placeholder="Type a message..."
          minRows={2}
        />
        <Button
          className="ml-2 p-2 bg-blue-500 text-white rounded"
          disabled={message.trim().length === 0}
          onClick={onSend}
        >
        <SendIcon className=''/>
        </Button>
      </div>
    </div>
  );
};

const CustomChat = ({ participantName }) => {
  const { chatMessages: messages, send } = useChat();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const onEnter = useCallback(
    (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        if (message.trim().length > 0 && send) {
          send(message).catch((err) => console.error(err));
          setMessage("");
        }
      }
    },
    [message, send]
  );

  const onSend = useCallback(() => {
    if (message.trim().length > 0 && send) {
      send(message).catch((err) => console.error(err));
      setMessage("");
    }
  }, [message, send]);

  return (
    <React.Fragment>
      <button onClick={() => setOpen(true)} className="rounded-md bg-gray-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900">
        <p className='flex font-semibold text-lg'>Chat&nbsp;&nbsp;<MessageSquareText/></p>
        </button>
      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <div className="w-80 h-full flex flex-col">
          <div className="flex items-center justify-between p-2 border-b border-gray-200 dark:border-gray-700">
            <div className="text-lg font-semibold">Chat</div>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <Divider />
          <div className="flex-1 overflow-y-auto">
            <Chat
              participantName={participantName}
              messages={messages}
              send={send}
              message={message}
              setMessage={setMessage}
              onEnter={onEnter}
              onSend={onSend}
            />
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default CustomChat;
