// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
// import { getAuth } from 'firebase/auth';
import { GoogleAuthProvider } from 'firebase/auth';

import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

  const firebaseConfig = {
    apiKey: "AIzaSyAcGZIpXUQylcgo2SDsDXRZbcKh66Q-xE4",
    authDomain: "shivradhyaiasweb.firebaseapp.com",
    projectId: "shivradhyaiasweb",
    storageBucket: "shivradhyaiasweb.appspot.com",
    messagingSenderId: "150693016119",
    appId: "1:150693016119:web:a1565534c136147f82c5b2"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log('Persistence set to local');
  })
  .catch((error) => {
    console.error('Error setting persistence:', error);
  });
// Initialize Firebase Auth

// auth.setPersistence(browserLocalPersistence)
//   .then(() => {
//     // New sign-in will be persisted with session persistence.
//     // return signInWithPopup(auth, provider);
//   })
//   .catch((error) => {
//     // Handle Errors here.
//     const errorCode = error.code;
//     const errorMessage = error.message;
//     console.error('Error setting persistence:', errorCode, errorMessage);
//   });
export const googleProvider = new GoogleAuthProvider();

export { auth };