
import React, { useState, useEffect } from "react";
import { HiMenuAlt3 } from "react-icons/hi";
import { MdOutlineDashboard } from "react-icons/md";
import { RiSettings4Line } from "react-icons/ri";
import { MdKey} from "react-icons/md";
import { Link, Navigate } from "react-router-dom";
import {Fullscreen, SidebarOpen, SidebarClose} from "lucide-react";
import { School , CheckCheck, Copy} from "lucide-react";
import {Button, IconButton} from "@mui/joy";
import  apiClient from "../../../config/AxiosConfig";
import CustomChat from "./CustomChat";
import {
    LiveKitRoom,
    LayoutContextProvider,
} from '@livekit/components-react';
import VideoFeed from "./VideoFeed";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const ViewerView = ({roomName}) => {
      const menus = [
        { name: "Stream", link: "/", icon: Fullscreen },
        // { name: "Settings", link: "/", icon: RiSettings4Line },
        { name: "Dashboard", link: "/panel", icon: MdOutlineDashboard, margin: true },
        { name: "Classroom", link: `/classpage/${roomName}`, icon: School }
      ];
      const theme = useTheme();
      const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
    
      const [open, setOpen] = useState(isLargeScreen);
      const [active, setActive] = useState(0);
      const liveKitUrl = "wss://livekit.shivradhyaias.com";
      const [livekitToken, setLivekitToken] = useState(null);
      const [loading, setLoading] = useState(true);
      useEffect(() => {
        const fetchToken = async () => {
          try {
            const response = await apiClient.get('/getTokenLiveStream', {
              params: {
                roomName: roomName
              }
            });
            setLivekitToken(response.data.token);
            setLoading(false);
          } catch (error) {
            console.log("Error: ", error);
            
            setLoading(false);
          }
        };
        fetchToken();
      }, [roomName]);
      const handleActive = (i) => { setActive(i); };
    
      return (
        <section className="flex gap-1 bg-gray-200">
          <div
            className={`bg-[#0e0e0e] min-h-screen ${
              open ? "w-72" : "w-16 pr-0.5"
            } duration-500 text-gray-100 px-4`}
          >
            <div className="py-3 flex justify-end">
              {open?<SidebarClose
                size={26}
                className="cursor-pointer"
                onClick={() => setOpen(!open)}
              />:<SidebarOpen
                size={26}
                className="cursor-pointer"
                onClick={() => setOpen(!open)}
              />}
            </div>
            <div className="mt-4 flex flex-col gap-4 relative">
              {menus?.map((menu, i) => (
                <div
                  onClick={() => handleActive(i)}
                  key={i}
                  className={` ${
                    menu?.margin && "mt-20"
                  } group flex items-center text-sm  gap-3.5 font-medium p-2 hover:bg-gray-800 rounded-md`}
                >
                  <div>{React.createElement(menu?.icon, { size: "20" })}</div>
                  <h2
                    style={{
                      transitionDelay: `${i + 3}00ms`,
                    }}
                    className={`whitespace-pre duration-500 ${
                      !open && "opacity-0 translate-x-28 overflow-hidden z-10"
                    }`}
                  >
                    {menu?.name}
                  </h2>
                  <h2
                    className={`${
                      open && "hidden"
                    } absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
                  >
                    {menu?.name}
                  </h2>
                </div>
              ))}
            </div>
          </div>
          <div className="m-3 ml-0 text-xl text-gray-900 font-semibold w-full ">
            {active===0 && (loading ? (<div className="p-6 font-bold">
          Can't Watch Stream Now. Please Try Again.
        </div>): (<div className="p-6 md:pd-3 sm:pd-2 xs:pd-1 -z-10">
              <LayoutContextProvider>
                <LiveKitRoom
                  token={livekitToken}
                  serverUrl={liveKitUrl}
                  // data-lk-theme="default"
                  className="grid grid-cols-1 lg:gap-y-0  h-full"
                >                
                  <div className="space-y-4 col-span-1 lg:overflow-y-auto hidden-scrollbar">
                    <VideoFeed roomName={roomName} />
                  </div>
                  <div className="col-span-1 lg:col-span-1 xl:col-span-1 2xl:col-span-1 mt-2">
                    <CustomChat participantName="Host" />
                  </div>
                </LiveKitRoom>
              </LayoutContextProvider>
              </div>))
            }
            {active===1 && <Navigate to={menus[active]?.link} />}
            {active===2 && <Navigate to={menus[active]?.link} />}
          </div>
        </section>
      );
    };
    
    export default ViewerView;