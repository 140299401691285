// src/LiveMeet.js
import React,{useEffect,useState} from 'react';
import { Box, Button, Typography } from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';

const LiveMeet = ({code}) => {
  const navigate = useNavigate();
  const handleStartMeet = () => {
    // Logic to start the live meet (e.g., open a new window or redirect to a live meeting URL)
    //alert('Starting live meet...');
    console.log('Starting live meet...:', code);
    navigate(`/classpage/${code}/live-meet`);
  };
  const handleStartStream = () => {
    // Logic to start the live stream (e.g., open a new window or redirect to a live stream URL)
    //alert('Starting live stream...');
    console.log('Starting live stream...:', code);
    navigate(`/classpage/${code}/livestream`);
  };
  const [userType, setUserType] = useState('student'); // ['student', 'teacher', 'admin'
  useEffect(() => {
    const jwtToken = localStorage.getItem('jwtToken');
    if (!jwtToken) {
      navigate('/');
    }
    const tokenPayload = jwtDecode(jwtToken);
    //console.log(tokenPayload);
    setUserType(tokenPayload.role);
  }, []);
  return (
    <Box sx={{ padding: 2, border: '1px solid #ccc', borderRadius: 2, boxShadow: 2 }}>
      <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
        {userType === 'teacher' || userType === 'admin' ? 'Start Live Stream' : 'Join Live Stream'}
      </Typography>
      {/* live-meet */}
      {userType === 'teacher' || userType === 'admin' ?
      <button onClick={handleStartMeet} className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
        Start Meet</button>:
        <button onClick={handleStartMeet} className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
        Join Meet</button>}
      <span className="mx-2 p-2 font-semibold text-lg">or</span>
      {/* live stream */}
      {userType === 'teacher' || userType === 'admin' ? 
      <button onClick={handleStartStream} className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
      Start Live Stream</button>:
      <button onClick={handleStartStream} className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
      Join Live Stream</button>}
    </Box>
  );
};

export default LiveMeet;
