
import React from "react";
import { auth, googleProvider } from "../../config/firebaseConfig";
import { signInWithPopup, signInWithEmailAndPassword } from 'firebase/auth';
import axios from "axios";
import baseURL from "../../config/baseURl";
  const handleGoogleSignIn = async () => {
    try {
      console.log("Initiating Google Sign-In");
      const result = await signInWithPopup(auth, googleProvider);
      console.log("Google Sign-In Res:", result);
      const token = await result.user.getIdToken();
      console.log("Firebase ID token:", token);
      const axiosFirebase = axios.create({
        baseURL: baseURL,
      });
      console.log("axiosFirebase:", axiosFirebase);
      // Add a request interceptor to include the JWT token in headers
      axiosFirebase.interceptors.request.use(
        (config) => {
          if (token) {
            //alert("tokenaf", token);
            config.headers.Authorization = `Bearer ${token}`;
          }
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
      console.log("axios api call");      

    } catch (error) {
      console.error("Error during Google Sign-In", error);
      throw error;
    }
  };


export default handleGoogleSignIn;
