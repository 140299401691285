import React, { useState, useCallback, useMemo } from 'react';
import {
  LiveKitRoom,
  VideoConference,
  useToken,
  LocalUserChoices,
  PreJoin,
} from '@livekit/components-react';
import { useNavigate } from 'react-router-dom';
import  ActiveRoom  from './ActiveRoom';
import { jwtDecode } from 'jwt-decode';

const PreJoinPage = ({roomName}) => {
  const [preJoinChoices, setPreJoinChoices] = useState(undefined);
  const navigate = useNavigate();
  const preJoinDefaults = useMemo(() => ({
    username: jwtDecode(localStorage.getItem('jwtToken')).name,
    videoEnabled: false,
    audioEnabled: false,
  }), []);

  const handlePreJoinSubmit = useCallback((values) => {
    console.log('PreJoin values:', values);
    console.log('PreJoin RoomName:', roomName);
    setPreJoinChoices(values);
  }, []);

  const onPreJoinError = useCallback((e) => {
    console.error(e);
  }, []);

  const onLeave = useCallback(() => {
    navigate(`/classroom`);
  }, []);

  return (
    <div >
      <main>
        {roomName && preJoinChoices ? (
          <ActiveRoom
            roomName={roomName}
            userChoices={preJoinChoices}
            onLeave={onLeave}
          />
        ) : (
          <div style={{ display: 'grid', placeItems: 'center', height: '100vh' }}>
            <PreJoin
              onError={onPreJoinError}
              defaults={preJoinDefaults}
              onSubmit={handlePreJoinSubmit}
              data-lk-theme="default"
            />
          </div>
        )}
      </main>
    </div>
  );
};

export default PreJoinPage;
