import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import axios from "axios"; // Import axios for making HTTP requests
import { jwtDecode } from "jwt-decode";
// import { PANELDATA } from "../panel/PANELDATA";
import "./Analytics.css";
import analy from "./analy.png"
import ReactLoading from 'react-loading';
import baseURL from '../../config/baseURl';

function Analytics() {

  const [data, setData] = useState(null);
  const [EX, setEx] = useState(null);
  const [cnt, setcnt] = useState(null);

  const [chartData, setChartData] = useState({});
  const [chartData1, setChartData1] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  const [exam, setexam] = useState(null)

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const decodedData = JSON.parse(decodeURIComponent(searchParams.get("dt")));
    console.log(decodedData);
    setexam(decodedData.exam);
  }, [])
  
  useEffect(() => {
    if(exam){
    const TOKEN = localStorage.getItem("token");
    const storedToken = jwtDecode(TOKEN);
    const email = storedToken.email;
    // const email = storedToken.email;
    const fetchData = async () => {
      try {
        const response = await axios.post(`${baseURL}/analysis`, {
          email,
          exam,
        });
        setEx(response.data.ex);
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Fetch data when component mounts
  }
  }, [exam]); // Dependency array ensures useEffect runs when email or exam changes

  useEffect(() => {
    if (data) {
      console.log("data",data);
      var correctCountFilter = data.filter(
        (item) => item.correctAns === item.answer
      ).length;
      var correctCountFilterB = data.filter(
        (item) => item.answer === "Not Selected" || item.answer === null || item.answer === undefined || item.correctAns === "Not Selected"
      ).length;
      var correctCountFilterA = data.filter(
        (item) => item.correctAns !== item.answer
      ).length;


      setcnt({
        a: correctCountFilter,
        b: correctCountFilterB,
        c: correctCountFilterA - correctCountFilterB,
      });
    }
  }, [data]);

  useEffect(() => {
    if(data && cnt){
    const DTT = {
        labels: [`${data.length-cnt.b} Answered`, `${cnt.b} Unanswered`],
        datasets: [
            {
                data: [data.length-cnt.b, cnt.b],
                backgroundColor: [
                    "#f7b120","#6366f1"
                ],
                hoverBackgroundColor: [
                   
                ]
            }
        ]
    };
    const options = {
        cutout: '60%'
    };

    setChartData(DTT);
    setChartOptions(options);
  }
}, [data,cnt]);
  useEffect(() => {
    if(data && cnt){
    const DTT = {
        labels: [`${cnt.a} Correct Answers`, `${cnt.c} Incorrect Answers`],
        datasets: [
            {
                data: [data.length-cnt.b, cnt.b],
                backgroundColor: [
                    "#14862e","#ff3a3a"
                ],
                hoverBackgroundColor: [
                   
                ]
            }
        ]
    };
    const options = {
        cutout: '60%'
    };

    setChartData1(DTT);
    setChartOptions(options);
  }
}, [data,cnt]);

  if (!data || !EX || !cnt) {
    return     <div className="bare">
      <ReactLoading type={"bars"} color={"black"} height={100} width={100}/>
      <div className="ld">LOADING...</div>
      </div>
    ;
  } else {
    return (
      <div className="analytics">
        <div className="ana_a">
          <div className="ana_a_l">
            <div className="ana_a_l_h">{EX[0].subexam} <span className="comp">Complete</span></div>
            <div className="ana_a_l_b">
              <div className="ana_a_l_b_ele">
                Total Questions: {data.length}
              </div>
              <div className="ana_a_l_b_ele">Total Marks: {EX[0].marks}</div>
              <div className="ana_a_l_b_ele">Length: {EX[0].time}</div>
              <div className="ana_a_l_b_ele">Your Marks: {cnt.a}</div>
            </div>
          </div>
          <div className="ana_a_r">
            <img src={analy} className="ana_a_img"/>
          </div>
        </div>
        <div className='ana_b'>
          <div className='ana_b_l'>
          <Chart type="doughnut" data={chartData} options={chartOptions} className="md:w-30rem" />

          </div>
          <div className='ana_b_l'>
          <Chart type="doughnut" data={chartData1} options={chartOptions} className="w-full md:w-30rem" />

          </div>
        </div>
        <div className="ana_c">
          <div className="ana_nav">
            <div className="ana_nav_qa">Question Answer</div>
            <div className="ana_nav_qa_r">
              <div className="ana_nav_qa_r_a">
                Total Questions: {data.length}
              </div>
              <div className="ana_nav_qa_r_b">Correct Answers: {cnt.a}</div>
              <div className="ana_nav_qa_r_c">Skipped Questions: {cnt.b}</div>
              <div className="ana_nav_qa_r_d">Incorrect Answers: {cnt.c}</div>
            </div>
          </div>
          {data.map((e) => (
            <div className="ana_c1">
              <div className="ques_ana">
                Q. {e.question}{" "}
                <span className="marks">
                  {e.answer === "Not Selected"
                    ? "Marks Awarded: 0"
                    : e.correctAns === e.answer
                    ? "Marks Awarded: 1"
                    : "Marks Awarded: 0"}
                </span>
              </div>
              <div className="options">
                <div className="options">
                  {e.options.map((option, index) => {
                    const optionKey = `option${index}`;

                    return (
                      <div key={index} className="oo opt">
                        <label htmlFor={`option${index}`} className="af">
                          <div
                            className={`${
                              e.answer === "Not Selected"
                                ? e.correctAns === option
                                  ? "selected"
                                  : "gs"
                                : option === e.correctAns
                                ? "selected"
                                : option === e.answer
                                ? "erro"
                                : "gs"
                            }`}
                          >
                            {String.fromCharCode(65 + index)}
                          </div>{" "}
                          <div>{option}</div>
                        </label>
                        <input
                          type="radio"
                          id={optionKey}
                          name="option"
                          value={option}
                          checked={e.answer === option}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="ans_type">
                  {e.correctAns === e.answer && (
                    <div className="ca">Answer is correct</div>
                  )}
                  {e.answer === "Not Selected" && (
                    <div className="sa">Skipped the question</div>
                  )}
                  {e.answer !== "Not Selected" &&
                    e.correctAns !== e.answer && (
                      <div className="ia">Answer is incorrect</div>
                    )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
export default Analytics;
