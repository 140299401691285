
import React, { useEffect, useState } from 'react';
import {jwtDecode} from 'jwt-decode';
import ViewerView from './ViewerView';
import HostView from './HostView';
export default function Streaming() {
    const [userType, setUserType] = useState(''); // ['student', 'teacher', 'admin']
    const [roomName, setRoomName] = useState('');
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const token = localStorage.getItem('jwtToken');
        if (token) {
            const decoded = jwtDecode(token);
            setUserType(decoded.role);
        }
        let room_name = window.location.pathname.split('/')[2];
        setRoomName(room_name);
        setLoading(false);
    }, []);
    if(loading){
        return <p>Loading...</p>
    }
    if(userType === 'teacher' || userType=== 'admin'){
        return (
            <HostView room_name={roomName} />
        );
    }  
    else if(userType === 'student'){
        return (
            <ViewerView roomName={roomName} />
        );
    } 
    return (
        <div>Something went wrong!</div>
    );
    }
