import axios from 'axios';
import baseURL from './baseURl';
import { auth } from './firebaseConfig';

// Create an Axios instance
const apiClient = axios.create({
  baseURL: baseURL,
});

// Function to get the Firebase ID token
const getFirebaseToken = async () => {
  const currentUser = auth.currentUser;
  if (currentUser) {
    try {
      return await currentUser.getIdToken();
    } catch (error) {
      console.error('Error getting ID token:', error);
      return null;
    }
  }
  return null;
};

// Add a request interceptor to include the JWT token in headers
apiClient.interceptors.request.use(
  async (config) => {
    const token = await getFirebaseToken(); // Wait for the token retrieval
    //console.log("APItoken", token);
    if (token) {

      config.headers.Authorization = `Bearer ${token}`;
      //alert("token", token);
    }
    else{
      //alert("token not found");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiClient;
