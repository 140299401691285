import React, { useState } from "react";
import "./Register.css";
import grp from "./group.png";
import apiClient from "../../config/AxiosConfig";
import baseURL from "../../config/baseURl";

function Register() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [type, setType] = useState("0");

  const handleRegister = async (e) => {
    e.preventDefault();
  
    // Reset errors
    setNameError("");
    setEmailError("");
    setMobileError("");
    setPasswordError("");
  
    // Client-side validation
    let hasError = false;
  
    if (type === "0") {
      if (!name) {
        setNameError("Name is required");
        hasError = true;
      }
      if (!mobile) {
        setMobileError("Mobile number is required");
        hasError = true;
      } else if (!validateMobile(mobile)) {
        setMobileError("Invalid mobile number");
        hasError = true;
      }
    }
  
    if (!email) {
      setEmailError("Email is required");
      hasError = true;
    } else if (!validateEmail(email)) {
      setEmailError("Invalid email address");
      hasError = true;
    }
  
    if (!password) {
      setPasswordError("Password is required");
      hasError = true;
    } else if (!validatePassword(password)) {
      setPasswordError("Password must be at least 8 characters long");
      hasError = true;
    }
  
    if (hasError) {
      console.log(nameError,emailError,passwordError,mobileError);
      return; // Exit early if there are errors
    }
  
    try {


      const response = await fetch(`${baseURL}/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          mobile,
          password,
        }),
      });
  
      if (response.ok) {
        const data = await response.json();
        if (
          data.message !== "User not found" &&
          data.message !== "Password is incorrect" &&
          data.message !== "You have already registered, login instead" &&
          data.message!=="You have not registered yet"
        ) {
          localStorage.setItem("token", data.token);
          localStorage.setItem("jwtToken", data.token);
          console.log(data); // Log response from server
          window.location.reload();
        } else {
          alert(data.message);
        }
      } else {
        throw new Error("Failed to register. Please try again.");
      }
    } catch (error) {
      console.error(error);
      // Handle error here
    }
  };
  

  const validateEmail = (email) => {
    // Simple email validation using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateMobile = (mobile) => {
    // Simple mobile number validation
    const mobileRegex = /^\d{10}$/;
    return mobileRegex.test(mobile);
  };

  const validatePassword = (password) => {
    // Simple password validation (example: at least 8 characters)
    return password.length >= 8;
  };

  return (
    <form className="register" onSubmit={handleRegister}>
  <div className="reg_right">
    <div className="reg_head">{type === "0" ? "REGISTER" : "LOGIN"}</div>
    <div className="reg_body">Welcome, It’s quick and easy.</div>
    {type === "0" && <div className="reg_in_txt">Name</div>}
    {type === "0" && (
      <input
        type="text"
        className="in_reg"
        placeholder="Enter your name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
    )}
    {type === "0" && nameError && <div className="error">{nameError}</div>}
    <div className="reg_in_txt">Email</div>
    <input
      type="email"
      className="in_reg"
      placeholder="Enter your email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      required
    />
    {emailError && <div className="error">{emailError}</div>}
    {type === "0" && <div className="reg_in_txt">Mobile Number</div>}
    {type === "0" && (
      <input
        type="text"
        className="in_reg"
        placeholder="Enter your mobile number"
        value={mobile}
        onChange={(e) => setMobile(e.target.value)}
        required
      />
    )}
    {type === "0" && mobileError && (
      <div className="error">{mobileError}</div>
    )}
    <div className="reg_in_txt">Password</div>
    <input
      type="password"
      className="in_reg"
      placeholder="Enter strong password"
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      required
    />
    {passwordError && <div className="error">{passwordError}</div>}
    <div className="btns_r">
      <button type="submit" className="btn_ra">
        {type === "0" ? "Register Account" : "Login"}
      </button>
    </div>
    {type === "0" && (
      <div className="login_ins">
        <div className="lg_or">OR</div>
        <div className="log" onClick={() => setType("1")}>
          Already have an account?
        </div>
      </div>
    )}
  </div>
</form>

  );
}

export default Register;
