import '@livekit/components-styles';
import {
  ControlBar,
  GridLayout,
  LiveKitRoom,
  ParticipantTile,
  RoomAudioRenderer,
  
  // VideoConference,
} from '@livekit/components-react';
import { useTracks } from '@livekit/components-react';
import { Track } from 'livekit-client';
import { Box } from '@mui/material';
import ClassroomHeader from './classroomHeader';

import { useEffect, useState } from 'react';
import PreJoinPage from './LiveKit/PreJoin';

export default function App() {
  const [room, setRoom] = useState(null);


  useEffect(() => {
    //set room name from url localhost:3000/:roomname/live-meet
    const roomName = window.location.pathname.split('/')[2];
    console.log("Live Room: ",roomName);
    setRoom(roomName);
  }, []);

  

  const serverUrl = 'wss://tem-txeona50.livekit.cloud';
  
  return (
    <Box sx={{ border: '1px solid #ccc', borderRadius: 2, boxShadow: 2 }}>
    <ClassroomHeader/> 
    <PreJoinPage roomName={room} />

    </Box>
  );
}

function MyVideoConference() {
  // `useTracks` returns all camera and screen share tracks. If a user
  // joins without a published camera track, a placeholder track is returned.
  const tracks = useTracks(
    [
      { source: Track.Source.Camera, withPlaceholder: true },
      { source: Track.Source.ScreenShare, withPlaceholder: false },
    ],
    { onlySubscribed: false },
  );
  return (
    <GridLayout tracks={tracks} style={{ height: 'calc(100vh - var(--lk-control-bar-height))' }}>
      {/* The GridLayout accepts zero or one child. The child is used
      as a template to render all passed in tracks. */}
      <ParticipantTile />
    </GridLayout>
  );
  
}