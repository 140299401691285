export const DATA=[
      {
        "question": "What is the capital of France?",
        "options": ["London", "Berlin", "Paris", "Madrid"],
        "answer": "Paris"
      },
      {
        "question": "Who wrote 'To Kill a Mockingbird'?",
        "options": ["Harper Lee", "J.K. Rowling", "Stephen King", "George Orwell"],
        "answer": "Harper Lee"
      },
      {
        "question": "Which planet is known as the Red Planet?",
        "options": ["Venus", "Mars", "Jupiter", "Saturn"],
        "answer": "Mars"
      },
      {
        "question": "Who painted the Mona Lisa?",
        "options": ["Vincent van Gogh", "Leonardo da Vinci", "Pablo Picasso", "Michelangelo"],
        "answer": "Leonardo da Vinci"
      },
      {
        "question": "What is the chemical symbol for water?",
        "options": ["H2O", "CO2", "O2", "NaCl"],
        "answer": "H2O"
      },
      {
        "question": "What is the tallest mammal in the world?",
        "options": ["Giraffe", "Elephant", "Kangaroo", "Hippopotamus"],
        "answer": "Giraffe"
      },
      {
        "question": "Who invented the telephone?",
        "options": ["Thomas Edison", "Alexander Graham Bell", "Nikola Tesla", "Galileo Galilei"],
        "answer": "Alexander Graham Bell"
      },
      {
        "question": "Which is the largest ocean on Earth?",
        "options": ["Atlantic Ocean", "Indian Ocean", "Arctic Ocean", "Pacific Ocean"],
        "answer": "Pacific Ocean"
      },
      {
        "question": "Who wrote 'To Kill a Mockingbird'?",
        "options": ["Harper Lee", "J.K. Rowling", "Stephen King", "George Orwell"],
        "answer": "Harper Lee"
      },
      {
        "question": "Which planet is known as the Red Planet?",
        "options": ["Venus", "Mars", "Jupiter", "Saturn"],
        "answer": "Mars"
      },
      {
        "question": "Who painted the Mona Lisa?",
        "options": ["Vincent van Gogh", "Leonardo da Vinci", "Pablo Picasso", "Michelangelo"],
        "answer": "Leonardo da Vinci"
      },
      {
        "question": "What is the chemical symbol for water?",
        "options": ["H2O", "CO2", "O2", "NaCl"],
        "answer": "H2O"
      },
      {
        "question": "What is the tallest mammal in the world?",
        "options": ["Giraffe", "Elephant", "Kangaroo", "Hippopotamus"],
        "answer": "Giraffe"
      },
      {
        "question": "Who invented the telephone?",
        "options": ["Thomas Edison", "Alexander Graham Bell", "Nikola Tesla", "Galileo Galilei"],
        "answer": "Alexander Graham Bell"
      },
      {
        "question": "Which is the largest ocean on Earth?",
        "options": ["Atlantic Ocean", "Indian Ocean", "Arctic Ocean", "Pacific Ocean"],
        "answer": "Pacific Ocean"
      },
      {
        "question": "What is the chemical symbol for gold?",
        "options": ["Go", "Au", "Gd", "Ag"],
        "answer": "Au"
      },
      {
        "question": "Who wrote 'Hamlet'?",
        "options": ["William Shakespeare", "Charles Dickens", "Jane Austen", "Mark Twain"],
        "answer": "William Shakespeare"
      },
      {
        "question": "What is the smallest country in the world?",
        "options": ["Vatican City", "Monaco", "Liechtenstein", "Maldives"],
        "answer": "Vatican City"
      },
      {
        "question": "Who discovered penicillin?",
        "options": ["Alexander Fleming", "Marie Curie", "Louis Pasteur", "Jonas Salk"],
        "answer": "Alexander Fleming"
      },
      {
        "question": "What is the currency of Japan?",
        "options": ["Yuan", "Dollar", "Euro", "Yen"],
        "answer": "Yen"
      },
      {
        "question": "Who was the first woman to win a Nobel Prize?",
        "options": ["Marie Curie", "Rosalind Franklin", "Dorothy Hodgkin", "Barbara McClintock"],
        "answer": "Marie Curie"
      },
      {
        "question": "What is the chemical symbol for oxygen?",
        "options": ["O", "O2", "O3", "OH"],
        "answer": "O"
      },
      {
        "question": "Which planet is known as the 'Morning Star' or 'Evening Star'?",
        "options": ["Venus", "Mercury", "Mars", "Saturn"],
        "answer": "Venus"
      },
      {
        "question": "Who was the first man to walk on the moon?",
        "options": ["Neil Armstrong", "Buzz Aldrin", "Yuri Gagarin", "John Glenn"],
        "answer": "Neil Armstrong"
      },
      {
        "question": "Which country is known as the 'Land of the Rising Sun'?",
        "options": ["China", "India", "Japan", "Korea"],
        "answer": "Japan"
      },
      {
        "question": "Who painted 'The Starry Night'?",
        "options": ["Vincent van Gogh", "Leonardo da Vinci", "Pablo Picasso", "Claude Monet"],
        "answer": "Vincent van Gogh"
      },
      {
        "question": "What is the chemical symbol for carbon dioxide?",
        "options": ["CO", "CO2", "O2", "C2H5OH"],
        "answer": "CO2"
      }
    ]
  
  