import React, { useState } from 'react';
import { Box, Button, TextField, MenuItem, Typography, Snackbar } from '@mui/material';
import apiClient from '../../config/AxiosConfig';
import { useNavigate } from 'react-router-dom';

const CreateCourse = () => {
  const [courseData, setCourseData] = useState({
    title: '',
    description: 'No description available',
    course_price: 0,
    display_picture:'https://images.unsplash.com/photo-1527549993586-dff825b37782?auto=format&fit=crop&w=286',
    start_date: '',
    end_date: '',
    mode: 'Online',
    language: 'English',
  });
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    if(name === 'course_price' && value <= 0) {setCourseData({...courseData,[name]:0});return;}
    if(name === 'description' && value.length > 200) {setCourseData({...courseData,[name]:value.substring(0,200)});return;}
    setCourseData({ ...courseData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await apiClient.post('/createCourse', courseData);
      setMessage("Course created successfully. Redirecting to classroom...");
      setSuccess(true);
      
    } catch (error) {
      setMessage("Failed to create course. Please try again.");
      setError(true);
      console.error(error);
    }
  };



  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ display: 'flex', flexDirection: 'column', gap: 2, maxWidth: '600px', margin: 'auto' }}
    > 
      {success && <Snackbar open={success} autoHideDuration={3000} onClose={() => {setSuccess(false);navigate('/classroom')}} message={message} />}
      {error && <Snackbar open={error} autoHideDuration={3000} onClose={() => setError(false)} message={message} />}
      <Typography variant="h4" component="h1" gutterBottom>
        Create a New Course
      </Typography>
      <TextField
        label="Title"
        name="title"
        value={courseData.title}
        onChange={handleChange}
        required
      />
      <TextField
        label="Description"
        name="description"
        value={courseData.description}
        onChange={handleChange}
        multiline
        maxlength={200}
        rows={4}
      />
      <TextField
        label="Course Price"
        name="course_price"
        value={courseData.course_price}
        onChange={handleChange}
        type="number"
      />
      <TextField
        label="Display Picture"
        name="display_picture"
        value={courseData.display_picture}
        onChange={handleChange}
        type="url"
      />
      <TextField
        label="Start Date"
        name="start_date"
        value={courseData.start_date}
        onChange={handleChange}
        type="date"
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="End Date"
        name="end_date"
        value={courseData.end_date}
        onChange={handleChange}
        type="date"
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        select
        label="Mode"
        name="mode"
        value={courseData.mode}
        onChange={handleChange}
      >
        <MenuItem value="Online">Online</MenuItem>
        <MenuItem value="Offline">Offline</MenuItem>
        <MenuItem value="Hybrid">Hybrid</MenuItem>
      </TextField>
      <TextField
        select
        label="Language"
        name="language"
        value={courseData.language}
        onChange={handleChange}
      >
        <MenuItem value="English">English</MenuItem>
        <MenuItem value="Hindi">Hindi</MenuItem>
        <MenuItem value="English/Hindi">English/Hindi</MenuItem>
      </TextField>
      <Button type="submit" variant="contained" color="primary">
        Create Course
      </Button>
    </Box>
  );
};

export default CreateCourse;
