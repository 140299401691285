import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';

export default function ClassroomHeader() {

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                variant="outlined"
                aria-label="menu"
                sx={{ marginX: 2 }}
                component={Link}
                to="/"
            >
            <HomeIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1, color: 'white', textAlign: 'center', fontWeight:'bold' }} component={Link} to="/classroom">
                Shiv Radhya IAS Courses
                </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
}