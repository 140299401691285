import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import CourseItem from './courseItem'; // Adjust the import based on your file structure
//left arrow
import { FaArrowAltCircleLeft } from "react-icons/fa";
//right arrow
import { FaArrowAltCircleRight } from "react-icons/fa";


const CoursesCarousel = ({AllCourses}) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <div className="card">
      <Carousel 
        swipeable={true}
        draggable={false}
        showDots={true}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={4000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        arrows={false}
      >
        {AllCourses.map((courseData, index) => (
          <CourseItem key={index} courseData={courseData} />
        ))}
      </Carousel>
    </div>
  );
};

export default CoursesCarousel;
