export const PANELDATA = [
  ["M.P CIVIL JUDGE-2021"],

  [
    [
      "IAS1 - 2013 FREE MOCK TEST",
      [
        {
          question: "What is the capital of France?",
          options: ["London", "Berlin", "Paris", "Madrid"],
          answer: "Paris",
        },
        {
          question: "Who wrote 'To Kill a Mockingbird'?",
          options: [
            "Harper Lee",
            "J.K. Rowling",
            "Stephen King",
            "George Orwell",
          ],
          answer: "Harper Lee",
        },
        {
          question: "Which planet is known as the Red Planet?",
          options: ["Venus", "Mars", "Jupiter", "Saturn"],
          answer: "Mars",
        },
        {
          question: "Who painted the Mona Lisa?",
          options: [
            "Vincent van Gogh",
            "Leonardo da Vinci",
            "Pablo Picasso",
            "Michelangelo",
          ],
          answer: "Leonardo da Vinci",
        },
        {
          question: "What is the chemical symbol for water?",
          options: ["H2O", "CO2", "O2", "NaCl"],
          answer: "H2O",
        },
        {
          question: "What is the tallest mammal in the world?",
          options: ["Giraffe", "Elephant", "Kangaroo", "Hippopotamus"],
          answer: "Giraffe",
        },
      ],
      "300",
      "Beginner",
      "100",
      "1"
    ],
    [
      "IAS1 - 2022 FREE MOCK TEST",
      [
        {
          question: "What is the capital of France?",
          options: ["London", "Berlin", "Paris", "Madrid"],
          answer: "Paris",
        },
        {
          question: "Who wrote 'To Kill a Mockingbird'?",
          options: [
            "Harper Lee",
            "J.K. Rowling",
            "Stephen King",
            "George Orwell",
          ],
          answer: "Harper Lee",
        },
        {
          question: "Which planet is known as the Red Planet?",
          options: ["Venus", "Mars", "Jupiter", "Saturn"],
          answer: "Mars",
        },
        {
          question: "Who painted the Mona Lisa?",
          options: [
            "Vincent van Gogh",
            "Leonardo da Vinci",
            "Pablo Picasso",
            "Michelangelo",
          ],
          answer: "Leonardo da Vinci",
        },
       
      ],
      "310",
      "Beginner",
      "190",
      "1"
    ],
    [
      "IAS1 - 2021 FREE MOCK TEST",
      [
        {
          question: "What is the capital of France?",
          options: ["London", "Berlin", "Paris", "Madrid"],
          answer: "Paris",
        },
        {
          question: "Who wrote 'To Kill a Mockingbird'?",
          options: [
            "Harper Lee",
            "J.K. Rowling",
            "Stephen King",
            "George Orwell",
          ],
          answer: "Harper Lee",
        },
        {
          question: "Which planet is known as the Red Planet?",
          options: ["Venus", "Mars", "Jupiter", "Saturn"],
          answer: "Mars",
        },
        {
          question: "Who painted the Mona Lisa?",
          options: [
            "Vincent van Gogh",
            "Leonardo da Vinci",
            "Pablo Picasso",
            "Michelangelo",
          ],
          answer: "Leonardo da Vinci",
        },
        {
          question: "What is the chemical symbol for water?",
          options: ["H2O", "CO2", "O2", "NaCl"],
          answer: "H2O",
        },
        {
          question: "What is the tallest mammal in the world?",
          options: ["Giraffe", "Elephant", "Kangaroo", "Hippopotamus"],
          answer: "Giraffe",
        },
      ],
      "210",
      "Beginner",
      "140",
      "1"
    ],
  ],
  ["M.AC CIVIL JUDGE-2021"],
  [
    [
      "IAS1 - 2029 FREE MOCK TEST",
      [
        {
          question: "What is the capital of France?",
          options: ["London", "Berlin", "Paris", "Madrid"],
          answer: "Paris",
        },
        {
          question: "Who wrote 'To Kill a Mockingbird'?",
          options: [
            "Harper Lee",
            "J.K. Rowling",
            "Stephen King",
            "George Orwell",
          ],
          answer: "Harper Lee",
        },
        {
          question: "Which planet is known as the Red Planet?",
          options: ["Venus", "Mars", "Jupiter", "Saturn"],
          answer: "Mars",
        },
        {
          question: "Who painted the Mona Lisa?",
          options: [
            "Vincent van Gogh",
            "Leonardo da Vinci",
            "Pablo Picasso",
            "Michelangelo",
          ],
          answer: "Leonardo da Vinci",
        },
        {
          question: "What is the chemical symbol for water?",
          options: ["H2O", "CO2", "O2", "NaCl"],
          answer: "H2O",
        },
        {
          question: "What is the tallest mammal in the world?",
          options: ["Giraffe", "Elephant", "Kangaroo", "Hippopotamus"],
          answer: "Giraffe",
        },
      ],
      "170",
      "Beginner",
      "120",
      "1"
    ],
    [
      "IAS1 - 2012 FREE MOCK TEST",
      [
        {
          question: "What is the capital of France?",
          options: ["London", "Berlin", "Paris", "Madrid"],
          answer: "Paris",
        },
        {
          question: "Who wrote 'To Kill a Mockingbird'?",
          options: [
            "Harper Lee",
            "J.K. Rowling",
            "Stephen King",
            "George Orwell",
          ],
          answer: "Harper Lee",
        },
        {
          question: "Which planet is known as the Red Planet?",
          options: ["Venus", "Mars", "Jupiter", "Saturn"],
          answer: "Mars",
        },
        {
          question: "Who painted the Mona Lisa?",
          options: [
            "Vincent van Gogh",
            "Leonardo da Vinci",
            "Pablo Picasso",
            "Michelangelo",
          ],
          answer: "Leonardo da Vinci",
        },
        {
          question: "What is the chemical symbol for water?",
          options: ["H2O", "CO2", "O2", "NaCl"],
          answer: "H2O",
        },
        {
          question: "What is the tallest mammal in the world?",
          options: ["Giraffe", "Elephant", "Kangaroo", "Hippopotamus"],
          answer: "Giraffe",
        },
      ],
      "110",
      "Beginner",
      "190",
      "1"
    ],
    
  ],
];
