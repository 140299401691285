// src/ProtectedRoute.jsx
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './AuthContext';
import CircularProgress from '@mui/joy/CircularProgress';

const ProtectedRoute = () => {
  const { currentUser, isAuthenticated ,loading, setLoading } = useAuth();
  const [loadingPR, setLoadingPR] = useState(true);

  useEffect(() => {
    setLoadingPR(false);
    console.log('ProtectedRoute: currentUser:', currentUser);
  }, [currentUser]);

  if (loadingPR || loading) {
    return (
      <div>
        <p className="mt-4 text-2xl font-semibold text-indigo-600 sm:text-2xl">
        Checking user authentication...</p>;
        <CircularProgress />
      </div>
    );
  }

  if (!currentUser) {  
    return (
      <div>
        {console.log('User not logged in. Redirecting to login page...')}
        <Navigate to="/login" replace />;
      </div>
    );
  }

  return <Outlet />;
};

export default ProtectedRoute;
