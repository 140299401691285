import React, { useState, useEffect } from 'react';
import { TextField, Button, MenuItem, FormControl, InputLabel, Select, FormHelperText } from '@mui/material';
import { FileUpload } from 'primereact/fileupload';
import axios from 'axios';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {jwtDecode} from 'jwt-decode';
import {useNavigate} from 'react-router-dom';
import apiClient from '../../config/AxiosConfig';
import Add from '@mui/icons-material/Add';

const CreatePost = (code) => {

    const [userNames, setUserNames] = useState("");

    const navigate = useNavigate();
  const [form, setForm] = useState({
    title: '',
    author: '',
    course: code.code,
    type: 'Post',
    content: '',
    files: [],
    tags: '',
    status: 'Active',
    options: '',
  });

  useEffect(() => {
    //from jwt
    const jwtToken = localStorage.getItem('jwtToken');
    if (!jwtToken) {
        navigate('/');
    }
    const tokenPayload = jwtDecode(jwtToken);
    setUserNames(tokenPayload.name);
    //set author name
    setForm((prev) => ({ ...prev, author: userNames }));
}, [form.author, userNames]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.files);
    setForm((prev) => ({ ...prev, files }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const postData = { ...form, tags: typeof form.tags === 'string' ? form.tags.split(',') : form.tags,
    options: typeof form.options === 'string' ? form.options.split(',') : form.options, };
    //console.log('Post data:', postData);
    try {
      const response = await apiClient.post('/createPost', postData);
      //console.log(response.data);
      //reload the page
        window.location.reload();
      //alert('Post created successfully.');
      
    } catch (error) {
      console.error(error);
      alert('An error occurred while creating the post.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl fullWidth margin="normal">
        <TextField
          label="Title"
          name="title"
          value={form.title}
          onChange={handleInputChange}
          required
        />
      </FormControl>
      {/* set non editable author name */}

      <FormControl fullWidth margin="normal">
        <TextField
          label="Author"
          name="author"
            value={form.author}
            onChange={handleInputChange}
            disabled
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <TextField
          label="Course"
          name="course"
          value={form.course}
          onChange={handleInputChange}
          disabled
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel id="type-label">Type</InputLabel>
        <Select
          labelId="type-label"
          name="type"
          value={form.type}
          onChange={handleInputChange}
        >
          <MenuItem value="Post">Post</MenuItem>
          <MenuItem value="Poll">Poll</MenuItem>
          {/* <MenuItem value="LiveStream">LiveStream</MenuItem>
          <MenuItem value="Material">Material</MenuItem> */}
        </Select>
        <FormHelperText>Select the type of post</FormHelperText>
      </FormControl>
      
      <FormControl fullWidth margin="normal">
        <TextField
          label="Content"
          name="content"
          value={form.content}
          onChange={handleInputChange}
          required
          multiline
          rows={4}
        />
      </FormControl>
      {form.type === 'Poll' && (
        <FormControl fullWidth margin="normal">
          <TextField
            label="Options (comma separated)"
            name="options"
            value={form.options}
            onChange={handleInputChange}
            required
          />
        </FormControl>
      )}
      {/* <FormControl fullWidth margin="normal">
        <FileUpload
          name="files"
        />
      </FormControl> */}
      <FormControl fullWidth margin="normal">
        <TextField
          label="Tags (comma separated)"
          name="tags"
          value={form.tags}
          onChange={handleInputChange}
        />
      </FormControl>
        <FormControl  margin="normal">
            <Button type="submit" variant="contained" color="primary">
            <AddIcon sx={{ mr: 1 }} />
            Create Post
            </Button>
        </FormControl>
    </form>
  );
};

export default CreatePost;
