import React, { createContext, useState, useEffect, useContext } from 'react';
import { auth } from '../config/firebaseConfig';
import apiClient from '../config/AxiosConfig';
import { onAuthStateChanged , signOut, sendEmailVerification} from 'firebase/auth';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(true);
      console.log('User State Changed:', user);
      if (user) {
        //check if the user email is verified
        if (!user.emailVerified) {
          try{
            await sendEmailVerification(user);
            console.log('Email verification sent!');
          } catch (error) {
            console.error('Error sending email verification, Please try again later.', error);
            alert('Error sending email verification, Please try again later.');
          }
          signOutUser();
          setLoading(false);
          alert('Please verify your email before logging in. Verification email has been sent to your email address.');
          return;
        }
        try {
          const token = await user.getIdToken();
          console.log('Firebase ID token:', token);
          console.log("User signed in", user);
          setCurrentUser(user);
          setIsAuthenticated(!!user);
          await refreshToken(token);
        } catch (error) {
          signOutUser();
          console.error('Error getting ID token:', error);
        }
      } else {
        console.log("User signed out");
        signOutUser();
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  const refreshToken = async (firebaseToken) => {
    try {
      const response = await apiClient.post('/firebase-login', { token: firebaseToken });
      const { token: jwtToken } = response.data;
      localStorage.setItem('jwtToken', jwtToken);
      localStorage.setItem('token', jwtToken);
    } catch (error) {
      console.error('Error during token refresh:', error);
      signOutUser();
    }
  };

  const signOutUser = () => {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('token');
    setIsAuthenticated(!!currentUser);
    setCurrentUser(null);
    delete apiClient.defaults.headers.common['Authorization'];
    return signOut(auth);
  };

  const value = {
    currentUser: currentUser,
    setCurrentUser: setCurrentUser,
    signOut: signOutUser,
    isAuthenticated: isAuthenticated,
    setIsAuthenticated: setIsAuthenticated,
    loading: loading,
    setLoading: setLoading,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
