import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./Order.css"
function PT(props) {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      setOrders(props.filteredOrders);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  return (
    <div>
      <h2 className='order_txt'>ORDERS</h2>
      <table className='table'>
        <thead className='table_tr'>
          <tr>
            <th style={{padding:"10px"}}>Sr No.</th>
            <th style={{padding:"10px"}}>Exam</th>
            <th style={{padding:"10px"}}>Status</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order, index) => (
            <tr key={order._id}>
              <td style={{fontWeight:"bold"}}>{index + 1}</td>
              <td style={{fontWeight:"bold"}}>{order.exam}</td>
              <td style={{fontWeight:"bold"}}>{(order.status).toUpperCase()==="SUCCEEDED"?"Payment Done":"Payment Failed"}</td>
            
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default PT;
