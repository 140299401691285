import styled from 'styled-components';
import sri from './images/sri.png';
const ResponsiveImage = styled.img`
  width: 250px;
  margin-top: 13px;
  margin-right: 80px;

  @media (max-width: 1200px) {
    width: 250px;
    margin-right: 60px;
  }

  @media (max-width: 992px) {
    width: 220px;
    margin-right: 40px;
  }

  @media (max-width: 768px) {
    width: 180px;
    margin-right: 20px;
  }

  @media (max-width: 576px) {
    width: 140px;
    margin-right: 10px;
  }
`;

const MyComponent = () => {
  return <ResponsiveImage src={sri} alt="Logo" />;
};

export default MyComponent;
