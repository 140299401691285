import React, { useRef, useEffect } from 'react';
import {
  LiveKitRoom,
  VideoConference,
  formatChatMessageLinks,
  useToken,
  useConnectionState,
  useRemoteParticipant,
  useChat,
  VideoTrack,
  useTracks,

} from '@livekit/components-react';
import {
  ConnectionState,
  Room,
  RoomConnectOptions,
  RoomOptions,
  Track,
  VideoCodec,
  VideoPresets,
  Participant,
} from 'livekit-client';
import { Loader, WifiOff } from 'lucide-react';

export default function VideoFeed({ roomName }) {
  const videoRef = useRef(null);
  const wrapperRef = useRef(null);
  const connectionState = useConnectionState();
  const participant = useRemoteParticipant(`Host-${roomName}`);
  
  const tracks = useTracks([
    Track.Source.Camera,
    Track.Source.Microphone,
  ]);

  useEffect(() => {
    if (videoRef.current) {
      tracks.filter(track => track.participant.identity === `Host-${roomName}`)
        .forEach(track => {
          track.publication.track.attach(videoRef.current);
        });
        console.log("Tracks:",tracks);
    }
  }, [tracks, roomName]);

  let content;
  if (!participant && connectionState === ConnectionState.Connected) {
    content = (
      <div className='h-full flex flex-col space-y-4 aspect-video bg-black items-center justify-center'>
        <WifiOff className='h-10 w-10 text-muted-foregound text-white' />
        <p className='text-muted-foregound text-white'>Host is Offline</p>
      </div>
    );
  } else if (!participant || !tracks || tracks.length === 0) {
    content = (
      <div className='h-full flex flex-col aspect-video bg-black space-y-4 items-center justify-center'>
        <Loader className={ `h-10 w-10 text-muted-foregound animate-spin  text-white ${connectionState=='disconnected'?'hidden':''}` }/>
        <p className='text-muted-foregound capitalize text-white'>{connectionState}</p>
        {connectionState=='disconnected' && <button className='bg-slate-900 text-white rounded-md p-2' onClick={()=>{window.location.reload();}}>Refresh Stream</button>}
        {/* <div className='absolute top-0 h-full w-full opacity-0 hover:opacity-100 transition-all content-center'>
            <div className='absolute bottom-0 flex h-14 w-full items-center justify-between bg-gradient-to-r from-neutral-900 px-4'>
                <button className='bg-slate-900 text-white rounded-md p-2' onClick={()=>{window.location.reload();}}>Refresh Stream</button>
            </div>
        </div>  */}
      </div>
    );
  } else {
    content = (
      <div className='relative h-full flex aspect-video rounded-md border border-slate-900 bg-zinc-300 p-2 -z-0' ref={wrapperRef}>
        <video ref={videoRef} width='100%' autoPlay playsInline controls/>
        {/* <div className='absolute top-0 h-full w-full opacity-0 hover:opacity-100 transition-all'>
            <div className='absolute bottom-0 flex h-14 w-full items-center justify-between bg-gradient-to-r from-neutral-900 px-4'>
                <button className='bg-slate-900 text-white rounded-md p-2'>Fullscreen</button>
            </div>
        </div> */}
      </div>      
    );
  }

  return (
    <div>
      {content}
    </div>
  );
}
