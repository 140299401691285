// src/PollDisplay.jsx
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import { styled } from '@mui/system';
import apiClient from '../../config/AxiosConfig';
import {jwtDecode} from 'jwt-decode';

const PollCard = styled(Card)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows ? theme.shadows[2] : '0px 1px 3px rgba(0,0,0,0.2)', // Fallback value
}));

const OptionBox = styled(Paper)(({ theme, selected }) => ({
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: `1px solid ${selected ? '#7CB9E8' : '#ccc'}`,
  '&:hover': {
    backgroundColor: selected ? '#7CB9E8' : '#f0f0f0',
  },
  pointerEvents: selected ? 'none' : 'auto', // Disable hover and click if selected
}));

const PollDisplay = ({ poll }) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [hasVoted, setHasVoted] = useState(false);

  useEffect(() => {
    // Check if the user has already voted
    const token = localStorage.getItem('jwtToken');
    if (token) {
      const decodedToken = jwtDecode(token);
      const email = decodedToken.email;
      const userVoter = poll.voters.find(voter => voter.voterEmail === email);
      setHasVoted(!!userVoter);
      if (userVoter) {
        setSelectedOption(poll.options[userVoter.voterOptionIndex].value);
      }
    }
  }, [poll]);

  const handleOptionChange = (event) => {
    if (!hasVoted) {
      setSelectedOption(event.target.value);
    }
  };

  const handleVote = async () => {
    // Handle the vote submission logic here
    if (hasVoted) {
      alert('You have already voted in this poll.');
      return;
    }
    const optionIndex = poll.options.findIndex(option => option.value === selectedOption);
    if (optionIndex !== -1 && selectedOption !== '') {
      try {
        const response = await apiClient.post(`/castVote/${poll._id}`, { optionIndex });
        console.log(response.data);
        setHasVoted(true);
        poll.options[optionIndex].votes += 1; // Update votes count locally for immediate feedback
      } catch (error) {
        console.error(error);
        alert('An error occurred while casting vote.');
      }
    }
  };

  return (
    <PollCard>
      <CardContent>
        <Typography variant="h6" align="left" gutterBottom>
          {poll.content}
        </Typography>
        <RadioGroup
          aria-label="poll-options"
          name="poll-options"
          value={selectedOption}
          onChange={handleOptionChange}
        >
          {poll.options.map((option, index) => (
            <OptionBox
              key={index}
              selected={selectedOption === option.value}
              onClick={() => handleOptionChange({ target: { value: option.value } })}
            >
              <FormControlLabel
                value={option.value}
                control={<Radio disabled={hasVoted} />}
                label={
                  <Typography variant="body2" color="textSecondary">
                    {option.value}
                  </Typography>
                }
                sx={{ margin: 0, flexGrow: 1 }}
              />
              <Typography variant="body2" color="textSecondary">
                {option.votes} votes
              </Typography>
            </OptionBox>
          ))}
        </RadioGroup>
        <Button
          variant="contained"
          color="primary"
          onClick={handleVote}
          disabled={!selectedOption || hasVoted}
          sx={{ mt: 2, alignSelf: 'flex-end' }}
        >
          Vote
        </Button>
      </CardContent>
    </PollCard>
  );
};

export default PollDisplay;
