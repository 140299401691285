import React, { useState } from "react";
import { VirtualScroller } from "primereact/virtualscroller";
import { classNames } from "primereact/utils";
import "./News.css";
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

function News() {
  const [visible, setVisible] = useState(false);

  const [items] = useState(
    Array.from({ length: 100 }).map((_, i) => (
      <>
      
      <a
        href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
        target={"_blank"}
        className="news_anchor"
        >{`Lorem Ipsum is simply dummy text of the printing and typesetting, Lorem Ipsum is simply dummy text`}</a>
        </>
    ))
  );

  const itemTemplate = (item, options) => {
    const className = classNames("flex align-items-center p-2", {
      "surface-hover": options.odd,
    });

    return (
      <div
        className={className}
        style={{ height: options.props.itemSize + "px" }}
      >
        {item}
      </div>
    );
  };
  const handleback = () => {
    window.location.href = "/";
  };

  return (
    <div className="mb50">
      
      <div className="vss">
        <div className="vscc">
          <div className="vsc">
          {items.map(e=>{
              return <div className="vsc_ele">{e}</div>
            })}
            </div>
        </div>
        <div className="vsc_t">
          <iframe
            className="news_if"
            
            src="https://www.youtube.com/embed/Ij2sMw4jUMo?si=GY_L0c-0jXQ03aR_"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
            style={{ margin: "20px", borderRadius: "10px" }}
          ></iframe>
          <iframe
            className="news_if"
            
            src="https://www.youtube.com/embed/2h3V2g58Whg?si=B8AQ1zWU7VXauXW_"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
            style={{ margin: "20px", borderRadius: "10px" }}
          ></iframe>
        </div>
      </div>
     
      
    </div>
  );
}

export default News;
