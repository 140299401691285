import React, { useState } from "react";
import "./Admin.css";
import baseURL from "../../config/baseURl";
import { Editor } from "primereact/editor";

const Imp = () => {
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");
  const [input4, setInput4] = useState("");
console.log(input2);
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `${baseURL}/submitArticle`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ input1, input2 }),
        }
      );
      const data = await response.json();
      alert(data); // Handle response from server
    } catch (error) {
      alert("Error:", error);
    }
  };
  const handleSubmit1 = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `${baseURL}/submitUpdate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ input1: input3, input2: input4 }),
        }
      );
      const data = await response.json();
      alert(data); // Handle response from server
    } catch (error) {
      alert("Error:", error);
    }
  };

  return (
    <>
      <div className="imp">ARTICLES & UPDATES</div>
      <div className="imp">Today's Articles</div>
      <form onSubmit={handleSubmit} className="form_imp">
        <input
          type="text"
          value={input1}
          onChange={(e) => setInput1(e.target.value)}
          placeholder="Today's articles"
          className="imp_inp"
        />
        <Editor
          value={input2}
          onTextChange={(e) => setInput2(e.htmlValue)}
          style={{ height: "320px" }}
        />

        <button type="submit" className="imp_sub">
          SUBMIT
        </button>
      </form>

      <div className="imp" style={{ marginTop: "100px" }}>
        Important Updates
      </div>
      <form onSubmit={handleSubmit1} className="form_imp">
        <input
          type="text"
          value={input3}
          onChange={(e) => setInput3(e.target.value)}
          placeholder="Important Updates"
          className="imp_inp"
        />
        <Editor
          value={input4}
          onTextChange={(e) => setInput4(e.htmlValue)}
          style={{ height: "320px" }}
        />

        <button type="submit" className="imp_sub">
          SUBMIT
        </button>
      </form>
    </>
  );
};

export default Imp;
