import React ,{useState,useEffect} from 'react';
import { Box, Typography, Card, CardContent, CardMedia, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import apiClient from '../../../config/AxiosConfig';
const useStyles = makeStyles({
    root: {
        display: 'flex',
        overflowX: 'auto',
        padding: '10px 0',
    },
    card: {
        minWidth: 200,
        marginRight: 10,
    },
});

function People({ code }) {
    const classes = useStyles();
    const [instructors, setInstructors] = React.useState([]);
    const [students, setStudents] = React.useState([]);
    useEffect(() => {
        const fetchPeople = async () => {
            try {
                const response = await apiClient.get(`/enrolledPeople/${code}`);
                setInstructors(response.data.instructors);
                // for (let i = 0; i < 20; i++) {
                //     setInstructors((prev) => [...prev, ...response.data.instructors]);
                //     }
                setStudents(response.data.students);
            } catch (error) {
                console.error(error);
                alert('An error occurred while fetching people data.');
            }
        };
        fetchPeople();
    }
    , [code]);
    return (
        <div>
            <h2>Instructors</h2>
            <div className={classes.root}>
                {instructors && instructors.map(instructor => (
                    <Card className={classes.card} key={instructor.id} sx={{border: '1px solid #ccc', borderRadius: 2, boxShadow: 2 }}>
                        <CardContent>
                            <Typography variant="h6" component="div">
                                {instructor.name}
                            </Typography>
                            <Divider />
                            <Typography variant="body2" color="text.secondary">
                                {instructor.email}
                            </Typography>
                            {/* Add more details as needed */}
                        </CardContent>
                    </Card>
                ))}
            </div>

            <h2>Students</h2>
            <div className={classes.root}>
                {students && students.map(student => (
                    <Card className={classes.card} key={student.id} sx={{border: '1px solid #ccc', borderRadius: 2, boxShadow: 2 }}>
                        <CardContent>
                            <Typography variant="h6" component="div">
                                {student.name}
                            </Typography>
                            <Divider />
                            <Typography variant="body2" color="text.secondary">
                                {student.email}
                            </Typography>
                            {/* Add more details as needed */}
                        </CardContent>
                    </Card>
                ))}
            </div>
        </div>
    );
}

export default People;
