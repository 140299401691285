import React, { useEffect, useState } from "react";
import "./Panel.css";
import { CiHome } from "react-icons/ci";
import { CgProfile } from "react-icons/cg";
import { LuBookOpen } from "react-icons/lu";
import { PiCurrencyInrBold } from "react-icons/pi";
import { IoMdCart } from "react-icons/io";
import axios from "axios";
import { IoIosSettings } from "react-icons/io";
import { CiLogout } from "react-icons/ci";
import Card from "../cards/Card";
import { PANELDATA } from "./PANELDATA";
import { FaChevronLeft } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa6";
import Order from "../table/Order";
import PT from "../table/PT";
import { jwtDecode } from "jwt-decode";
import  apiClient from "../../config/AxiosConfig";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { IoArrowRedo } from "react-icons/io5";
import Myexams from "./Myexams";
import baseURL from "../../config/baseURl";
import { useAuth } from "../../Authentication/AuthContext";
import {useNavigate} from 'react-router-dom';
import { SiGoogleclassroom } from "react-icons/si";
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import CourseBox from "../classroom/courseTab";

function Panel() {
  const ITEMS_PER_PAGE = 4; // Number of items per page
  const [currentPage, setCurrentPage] = useState(0); // Current page index
  
  const [name, setname] = useState("User");
  const [email, setemail] = useState(null);
  const [answers, setanswers] = useState(null);
  const [mobile, setmobile] = useState(null);
  const [orders, setOrders] = useState(null);
  const [FO, setFO] = useState(null)
  const [FF, setFF] = useState(null)
  const navigate = useNavigate();
  const {signOut} = useAuth();
  // Calculate the index range for the current page
  const startIndex = currentPage * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;

  // State to store the selected index
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [exams, setExams] = useState(null);
  const [myCourses, setMyCourses] = useState([]);
  const [currentCourseIndex, setCurrentCourseIndex] = useState(0);
  
  // Handler function for dropdown change
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    console.log(selectedValue);
    let VALD=0;
    for (let ind = 0; ind < exams.length; ind++) {
      const element = exams[ind];
      if(element._id===selectedValue){
        VALD=ind;
        break;
      }
      
    }
    // if (index !== -1) {
    //   setSelectedIndex(index);
    // }
    setSelectedIndex(VALD);
  };
  const findKeyWord = (title) => {
    return orders.includes(title);
  };

  const handleOrders = async (email) => {
    // const resp = await fetch("http://localhost:5000/orders", {
    const resp = await fetch(`${baseURL}/orders`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
      }),
    });

    const response = await resp.json();
    setFF(response);
    // const response = await axios.get(`${process.env.REACT_APP_API_URL}/orders`);
    const filteredOrders = response.filter(
      (order) => order.status.toUpperCase() === "SUCCEEDED"
    );
    setFO(filteredOrders);
    const filterArr = filteredOrders.map((e) => e.exam);
    setOrders(filterArr);
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    const data = jwtDecode(token);
    setname(data.name);
    setemail(data.email);
    setmobile(data.mobile);
    handleOrders(data.email);
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        //send auth token with request
        const response = await apiClient.get(`${baseURL}/exams`);

        console.log("Exams"+response.data);
        setExams(response.data);
        
      } catch (error) {
        console.error("Error fetching exams:", error.message);
      }
    };
    fetchData();
  }, []);

  // Fetching my courses
  useEffect(() => {
    const fetchMyCourses = async () => {
      try {
        const response = await apiClient.get('/myCourses');
        setMyCourses(response.data.courses);
        console.log(response.data.courses);
      } catch (error) {
        console.error(error);
        // alert('An error occurred while fetching your courses.');
      }
    };
    fetchMyCourses();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseURL}/answers`);
        console.log("---++++----", response.data);
        setanswers(response.data);
      } catch (error) {
        console.error("Error fetching exams:", error.message);
      }
    };
    fetchData();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/', { replace: true });
    } catch (error) {
      console.error('Error signing out:', error);
      window.location.reload();
    }

  };
  const [visible, setVisible] = useState(false);
  const [task, setTask] = useState("6");
  const handleNameChange = (e) => {
    setname(e.target.value);
  };

  // Handler for updating email
  const handleEmailChange = (e) => {
    setemail(e.target.value);
  };

  // Handler for updating mobile
  const handleMobileChange = (e) => {
    setmobile(e.target.value);
  };

  const handleUpdate = async () => {
    try {
      // Make POST request to backend
      // const response = await fetch("http://localhost:5000/update", {
      const response = await fetch(`${baseURL}/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          mobile,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        if (data.t !== "0") {
          localStorage.removeItem("token");
          localStorage.setItem("token", data.t);
        }
        alert(data.message);
        // Optionally, you can handle success response here
      } else {
        alert("Failed to Update. Please try again.");
      }
    } catch (error) {
      alert(error);
      // Handle error here
    }
  };
  if (!orders || !exams || !FO || !answers) {
    return <div>Loading</div>;
  } else {
    return (
      <div>
      
        <div className="ts_a">
          <div className="ts_a_txt" onClick={() => navigate('/', { replace: true })}>
            SHIV RADHYA IAS
          </div>
          <div className="ts_a_icon">
            <div>Hi {name}!</div>
            <div className="ts_ic">
              <CgProfile className="icon" />
            </div>
          </div>
        </div>
        <div className="panel">
          <div className="small_pl">
            <Sidebar visible={visible} onHide={() => setVisible(false)}>
              <div className="panel_l" style={{ backgroundColor: "white" }}>
                <div className="panel_l_ele" onClick={() => setTask("1")}>
                  <div className="panel_icon">
                    <CiHome className="icon_panel" />
                  </div>
                  <div className="panel_txt">Dashboard</div>
                </div>
                <div className="panel_l_ele" onClick={() => setTask("2")}>
                  <div className="panel_icon">
                    <CgProfile className="icon_panel" />
                  </div>
                  <div className="panel_txt">Profile</div>
                </div>
                <div className="panel_l_ele" onClick={() => setTask("3")}>
                  <div className="panel_icon">
                    <LuBookOpen className="icon_panel" />
                  </div>
                  <div className="panel_txt">Exams</div>
                </div>
                <div className="panel_l_ele" onClick={() => setTask("4")}>
                  <div className="panel_icon">
                    <PiCurrencyInrBold className="icon_panel" />
                  </div>
                  <div className="panel_txt">Payments</div>
                </div>
                <div className="panel_l_ele" onClick={() => setTask("5")}>
                  <div className="panel_icon">
                    <IoMdCart className="icon_panel" />
                  </div>
                  <div className="panel_txt">Orders</div>
                </div>
                {/* section for showing my course */}
                <div className="panel_l_ele" onClick={() => setTask("6")}>
                  <div className="panel_icon">
                    <SiGoogleclassroom className="icon_panel" />
                  </div>
                  <div className="panel_txt">My Courses</div>
                </div>
                <div className="panel_l_ele logout" onClick={handleLogout}>
                  <div className="panel_icon">
                    <CiLogout className="icon_panel" />
                  </div>
                  <div className="panel_txt">Logout</div>
                </div>
              </div>
            </Sidebar>
            <IoArrowRedo
              onClick={() => setVisible(true)}
              className="get_panel"
            />
          </div>
          <div className="panel_l big_pl" style={{ backgroundColor: "white" }}>
            <div className="panel_l_ele" onClick={() => setTask("1")}>
              <div className="panel_icon">
                <CiHome className="icon_panel" />
              </div>
              <div className="panel_txt">Dashboard</div>
            </div>
            <div className="panel_l_ele" onClick={() => setTask("2")}>
              <div className="panel_icon">
                <CgProfile className="icon_panel" />
              </div>
              <div className="panel_txt">Profile</div>
            </div>
            <div className="panel_l_ele" onClick={() => setTask("3")}>
              <div className="panel_icon">
                <LuBookOpen className="icon_panel" />
              </div>
              <div className="panel_txt">Exams</div>
            </div>
            <div className="panel_l_ele" onClick={() => setTask("4")}>
              <div className="panel_icon">
                <PiCurrencyInrBold className="icon_panel" />
              </div>
              <div className="panel_txt">Payments</div>
            </div>
            <div className="panel_l_ele" onClick={() => setTask("5")}>
              <div className="panel_icon">
                <IoMdCart className="icon_panel" />
              </div>
              <div className="panel_txt">Orders</div>
            </div>
            {/* <div className="panel_l_ele" onClick={() => setTask("6")}>
            <div className="panel_icon">
              <IoIosSettings className="icon_panel" />
            </div>
            <div className="panel_txt">Settings</div>
          </div> */}
            <div className="panel_l_ele" onClick={() => setTask("6")}>
              <div className="panel_icon">
                <SiGoogleclassroom className="icon_panel" />
                </div>
              <div className="panel_txt">My Courses</div>
            </div>
            <div className="panel_l_ele logout" onClick={handleLogout}>
              <div className="panel_icon">
                <CiLogout className="icon_panel" />
              </div>
              <div className="panel_txt">Logout</div>
            </div>
          </div>
          {(task === "1" || task === "3") && (
            <div className="panel_r">
              {/* <PT /> */}

              <select
                id="examSelect"
                onChange={handleSelectChange}
                value={exams[selectedIndex]._id}
                className="dd"
              >
                {exams.map((exam) => (
                  <option key={exam._id} value={exam._id}>
                    {exam._id}
                  </option>
                ))}
              </select>

              <div>
                <div className="panel_cards">
                  {exams[selectedIndex].data
                    .slice(startIndex, endIndex)
                    .map((e, index) => (
                      <Card
                        key={index}
                        title={e.subexam}
                        data={e.test}
                        marks={e.marks}
                        level={e.level}
                        length={e.time}
                        email={email}
                        amount={e.cost}
                        mode={!orders.includes(e.subexam) ? "1" : "0"}
                        name={name}
                        answers={answers}
                      />
                    ))}
                </div>
                <div className="mt-10">
                  {/* Pagination controls */}
                  <button className="disabled:opacity-50"
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 0}
                  >
                    <FaChevronLeft className="pagina_l" />
                  </button>
                  <button className="disabled:opacity-50"
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={endIndex >= PANELDATA[selectedIndex + 1].length}
                  >
                    <FaChevronRight className="pagina_r" />
                  </button>
                </div>
              </div>
            </div>
          )}
          {task === "2" && (
            <div
              className="panel_r"
              style={{
                width: "100%",
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <div className="profile_l">
                <CgProfile className="prof_l_icon" />
              </div>
              <div className="profile_r">
                {/* Input field for Name */}
                <input
                  type="text"
                  value={name}
                  onChange={handleNameChange}
                  placeholder="Name"
                  className="inp_prof"
                />

                {/* Input field for Email */}
                <input
                  type="email"
                  value={email || ""}
                  onChange={handleEmailChange}
                  placeholder="Email"
                  className="inp_prof"
                />

                {/* Input field for Mobile */}
                <input
                  type="text"
                  value={mobile || ""}
                  onChange={handleMobileChange}
                  placeholder="Mobile"
                  disabled // Mobile field is disabled
                  className="inp_prof"
                />
                <div className="upd_btn" onClick={handleUpdate}>
                  UPDATE
                </div>
              </div>
            </div>
          )}
          {/* {task === "3" && <Myexams exams={exams} filteredOrders={FO} answers={answers}/>} */}
          {task === "4" && <PT filteredOrders={FF}/>}
          {task === "5" && <Order filteredOrders={FO}/>}
          {task === "6" && (<div className="panel_r flex justify-center">
          {myCourses.length > 0 ? (
              <div>
                <div className="panel_cards flex items-center justify-center">
                    {myCourses.slice(currentCourseIndex, currentCourseIndex + 1).map((course, index) => (
                      <CourseBox key={index} courseData={course} />
                    ))
                  }
                </div>
                <div className="mt-10">
                  {/* Pagination controls */}
                  <button className="disabled:opacity-50"
                    onClick={() => setCurrentCourseIndex(Math.max(currentCourseIndex - 1, 0))}
                    disabled={currentCourseIndex === 0}
                  >
                    <FaChevronLeft className="pagina_l" />
                  </button>
                  <button className="disabled:opacity-50"
                    onClick={() => setCurrentCourseIndex(Math.min(currentCourseIndex + 1, myCourses.length - 1))}
                    disabled={currentCourseIndex >= myCourses.length - 1}
                  >
                    <FaChevronRight className="pagina_r" />
                  </button>
                </div>
                <Link to="/classroom" className="mt-10 mb-2 flex items-center justify-center gap-x-6 ">
                    <div
                     className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" 
                    >View All Courses</div>
                  </Link>
              </div>
              ) : (
                <div>
                  <div className="mt-6 text-base leading-7 text-gray-800">You have not enrolled in any courses yet!</div>
                  <Link to="/classroom" className="mt-10 flex items-center justify-center gap-x-6">
                    <div
                     className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" 
                    >Explore Courses</div>
                  </Link>
                </div>
              )
              }
          </div>)}

        </div>
      </div>
    );
  }
}

export default Panel;