import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithCustomToken } from 'firebase/auth';
import { useAuth } from '../../Authentication/AuthContext';
import { auth } from '../../config/firebaseConfig';
import axios from 'axios';
import baseURL from '../../config/baseURl';

const TokenHandler = () => {
    const navigate = useNavigate();
    const { setCurrentUser, setIsAuthenticated } = useAuth();
    const tokenProcessed = useRef(false);

    useEffect(() => {
        const storeTokenFromURL = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const token = urlParams.get('token');
            if (!token || tokenProcessed.current) return;

            tokenProcessed.current = true;  // Ensure this effect runs only once

            try {
                const userCredential = await signInWithCustomToken(auth, token);
                const user = userCredential.user;
                console.log('User signed in with custom token:', user);

                const newToken = await user.getIdToken();
                setCurrentUser(user);
                setIsAuthenticated(true);

                const axiosFirebase = axios.create({
                    baseURL: baseURL,
                });

                axiosFirebase.interceptors.request.use(
                    (config) => {
                        if (newToken) {
                            config.headers.Authorization = `Bearer ${newToken}`;
                        }
                        return config;
                    },
                    (error) => {
                        return Promise.reject(error);
                    }
                );

                const response = await axiosFirebase.post('/firebase-login', {});
                console.log("Server response:", response.data);

                const { token: jwtToken } = response.data;
                localStorage.setItem("jwtToken", jwtToken);
                localStorage.setItem("token", jwtToken);
                navigate('/panel');
            } catch (error) {
                console.error('Error signing in with custom token:', error);
                navigate('/');
            }
        };

        storeTokenFromURL();
    }, [navigate, setCurrentUser, setIsAuthenticated]);

    return (
        <div/>
    );
};

export default TokenHandler;
