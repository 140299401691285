import axios from "axios";
import React, { useState } from "react";
import { encode } from "string-encode-decode";
import RenderRazorpay from "./RenderRazorpay";
import queryString from 'query-string';
import baseURL from "../../config/baseURl";
import {useNavigate} from 'react-router-dom';

function Payment(props) {
  const navigate = useNavigate();
  const email = props.email;
  const exam = props.exam;
  const serverBaseUrl = `${baseURL}`;
  const [displayRazorpay, setDisplayRazorpay] = useState(false);
  const [orderDetails, setOrderDetails] = useState({
    orderId: null,
    currency: null,
    amount: null,
  });

  const handleCreateOrder = async (amount, currency) => {
    const data = await axios.post(serverBaseUrl + "/order", {
      amount: amount * 100, //convert amount into lowest unit. here, Dollar->Cents
      currency,
      keyId: "rzp_live_5ZV4D1PE89iW0C",
      KeySecret: "TcnD5Z3Nvy6r9OVY8wMViGch",
    });
    if (data && data.data && data.data.order_id) {
      setOrderDetails({
        orderId: data.data.order_id,
        currency: data.data.currency,
        amount: data.data.amount,
      });
      setDisplayRazorpay(true);
    }
  };


  const dataToEncode = {
    name: props.name,
    title: props.exam,
    data: props.data,
    level: props.level,
    length:props.length,
    email:props.email,
    marks:props.marks
  };
  
  // Encode the data using encodeURIComponent for each key-value pair
  const encodedData = encodeURIComponent(JSON.stringify(dataToEncode));

  

  return (
    <div>

     {props.mode==="1"? <button onClick={() => handleCreateOrder(props.amount, "INR")} className="sn">
        Register Now
      </button>:
      <div onClick={() => navigate(`/ts?dt=${encodedData}`)} className="sn" style={{textDecoration:"none"}}>Start Now</div>}
      {/* <a href={`/ts?dt=${encodedData}`} target={"_blank"} className="sn" style={{textDecoration:"none"}}>Start Now</a>} */}
      {displayRazorpay && (
        <RenderRazorpay
          amount={orderDetails.amount}
          currency={orderDetails.currency}
          orderId={orderDetails.orderId}
          keyId={"rzp_test_otYNEG73MSiVBf"}
          keySecret={"RzHvdnccOTZyevPgezrIRvHW"}
          email={email}
          exam={exam}
        />
      )}
    </div>
  );
}

export default Payment;
