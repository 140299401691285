import React, { useMemo, useEffect, useState } from 'react';
import {
  LiveKitRoom,
  VideoConference,
  formatChatMessageLinks,
  useToken,
} from '@livekit/components-react';
import {
  Room,
  RoomConnectOptions,
  RoomOptions,
  VideoCodec,
  VideoPresets,
} from 'livekit-client';
import apiClient from '../../../config/AxiosConfig';
import { Snackbar } from '@mui/material';
import CircularProgress from '@mui/joy/CircularProgress';
import { useNavigate } from 'react-router-dom';


const ActiveRoom = ({ roomName, userChoices, onLeave }) => {
  const tokenOptions = useMemo(() => ({
    userInfo: {
      identity: userChoices.username,
      name: userChoices.username,
    },
  }), [userChoices.username]);
  
  const [token, setToken] = useState(null);
  const navigate = useNavigate();

  const liveKitUrl = "wss://livekit.shivradhyaias.com";
  const [hostNotJoinedError, setHostNotJoinedError] = useState(false);
  useEffect(() => {
    console.log("Room Name: ",roomName);
    const fetchToken = async () => {
        try {
            const response = await apiClient.get('/gettoken', {
                params: {
                    roomName: roomName,
                    userInfo: tokenOptions.userInfo
                }
            });
            setToken(response.data.token);
        } catch (error) {
          // message: 'Host not found' status: 404
          if(error.response.data.message === 'Host not found'){
            setHostNotJoinedError(true);
          }
          else if(error.response.status === 404){
            console.log("Room not found");
            navigate('/error');
          }
          else if(error.response.status === 401){
            alert("Unauthorized access");
            console.log("Unauthorized access");
            navigate('/classroom');
          }
          else{
            console.log("Error: ",error);
            alert("Something went wrong!, Please try again later");
            navigate('/classroom');
          }
        }
    };

    fetchToken();

}, []);






  const roomOptions = useMemo(() => {
    return {
      videoCaptureDefaults: {
        deviceId: userChoices.videoDeviceId ?? undefined,
        resolution: VideoPresets.h720,
      },
      publishDefaults: {
        dtx: false,
        videoSimulcastLayers: [VideoPresets.h540, VideoPresets.h216],
        videoCodec: 'vp9',
      },
      audioCaptureDefaults: {
        deviceId: userChoices.audioDeviceId ?? undefined,
      },
      adaptiveStream: { pixelDensity: 'screen' },
      dynacast: true,
    };
  }, [userChoices]);

  const room = useMemo(() => new Room(roomOptions), [roomOptions]);

  const connectOptions = useMemo(() => ({
    autoSubscribe: true,
  }), []);

  const startScreenShare = async () => {
    if (!room) return;

    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({ video: true });
      const screenTrack = stream.getVideoTracks()[0];
      room.localParticipant.publishTrack(screenTrack, { source: 'screen' });
    } catch (error) {
      console.error('Error starting screen share:', error);
    }
  };

  const stopScreenShare = () => {
    if (!room) return;

    room.localParticipant.tracks.forEach((trackPublication) => {
      if (trackPublication.track && trackPublication.source === 'screen') {
        room.localParticipant.unpublishTrack(trackPublication.track);
        trackPublication.track.stop();
      }
    });
  };
  
  if (!token) {
    if(hostNotJoinedError){
        //Snackbar to show host has not joined
        return <div><Snackbar open={hostNotJoinedError} autoHideDuration={2000} onClose={onLeave} message="Host has not joined yet. Please try again later." />
         </div>
    }
    else {
    return <CircularProgress />;}
  }
  return (
    liveKitUrl && (
    <main data-lk-theme="dark" style={{ height: '100vh' }}>
    
      <LiveKitRoom
        room={room}
        token={token}
        serverUrl={liveKitUrl}
        connectOptions={connectOptions}
        video={userChoices.videoEnabled}
        audio={userChoices.audioEnabled}
        onDisconnected={onLeave}
        data-lk-theme="default"
        >
        <VideoConference
          chatMessageFormatter={formatChatMessageLinks}
        />
      </LiveKitRoom>
      </main>
    )
  );
};

export default ActiveRoom;
