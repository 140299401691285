//create course tabs for classroom

import React, { useState, useEffect,useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import CourseBox from './courseTab';
import ClassroomHeader from './classroomHeader';
import { Container, Fab, Grid } from '@mui/material';
import apiClient from '../../config/AxiosConfig';
import Add from '@mui/icons-material/Add';
import { Snackbar } from '@mui/material';
import CircularProgress from '@mui/joy/CircularProgress';
import { jwtDecode } from 'jwt-decode';
import { useAuth } from '../../Authentication/AuthContext';

export default function Classes() {
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const alertShownRef = useRef(false);
    const [token, settoken] = useState(null);
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    const [userType, setUserType] = useState(null); // ['student', 'teacher', 'admin']
    const[courseError,setCourseError] = useState(false);
    const {signOut} = useAuth();
    const logoutAndNavigate = () => {
        signOut();
        navigate('/');
    }
    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const response = await apiClient.get('/fetchAllCourses');
                setCourses(response.data.courses);
                setCourseError(false);
                setError(false);
            } catch (error) {
                setCourseError(true);
                console.error(error);
                //alert('An error occurred while fetching courses.');
            }
        };
        fetchCourses();
    }, []);
    useEffect(() => {
        const fetchtoken = async () => {
          try {
            let token = localStorage.getItem('jwtToken');
            //decode the token to get user details
            token = jwtDecode(token);
            settoken(token);
            setUserType(token.role);
            setError(false);

          } catch (error) {
            console.error(error);
            setError(true);
          }
          setLoading(false);
        };
    
        if (!token) {
          fetchtoken();
        }
    
      }, [token]);

      if(!loading) {
        if (error) {
          return <Snackbar open={error} autoHideDuration={3000} onClose={logoutAndNavigate} message="An error occurred while Loading data, Please login again." />;
        }
      }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <ClassroomHeader />
            {!courses.length && <CircularProgress />}
            <Snackbar open={courseError} autoHideDuration={3000}  message="Could not retrieve courses. Please Try again." onClose={()=>{setCourseError(false)}}/>
            <Box className="mt-2">
                <Grid container spacing={2} direction="row"
  className='flex flex-wrap justify-center items-center mb-2'
  sx={{}}>          {console.log(courses)}
                    {courses.map((course, index) => (
                    <Grid item xs={8} sm={6} md={4} lg={3} key={index} className=''> {/* flex for center*/}
                        <CourseBox courseData={course} />
                    </Grid>
                    ))}
                </Grid>
                {(userType === 'teacher' || userType === 'admin') &&
                <Fab variant="extended" color="primary" onClick={()=>{navigate('/classroom/createCourse')}}>
                    <Add sx={{ mr: 1 }} />
                    Create Course
                </Fab>}
                </Box>
        </Box>
    );
}
