// src/ClassStream.jsx
import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Container,
  Grid,
  Chip,
  Avatar,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/system';
import LiveMeet from './LiveStream';
import apiClient from '../../config/AxiosConfig';
import PollDisplay from './Poll';
        
const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const CourseHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  border: `2px solid #ccc`,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows ? theme.shadows[2] : '0px 1px 3px rgba(0,0,0,0.2)', // Fallback value
  marginBottom: theme.spacing(4)
}));

const PostCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  border: `2px solid #7CB9E8`,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows ? theme.shadows[2] : '0px 1px 3px rgba(0,0,0,0.2)', // Fallback value
}));

const ClassStream = ({ course }) => {
  const [classPosts, setClassPosts] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!course || !course.code) {
      setError('Course is not defined or does not have a code.');
      return;
    }

    const fetchClassPosts = async () => {
      try {
        const response = await apiClient.get(`/fetchAllPosts/${course.code}`);
        setClassPosts(response.data.posts);
      } catch (error) {
        console.error(error);
        setError('An error occurred while fetching class posts.');
      }
    };

    fetchClassPosts();
    setLoading(false);
  }, [course]);

  const sortedPosts = [...classPosts].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const renderTags = (tags) => {
    if (!tags) return null;
    const colors = ['error', 'warning', 'info', 'success'];
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginX: 1 }}>
        {tags.map((tag, index) => (
          <Chip key={index} label={`#${tag}`} color={colors[index % 4]} size="small" />
        ))}
      </Box>
    );
  };

  const renderFiles = (files) => {
    if (!files) return null;
    return files.map((file, index) => (
      <Typography key={index} variant="body2" color="textSecondary">
        <a href={file.url} target="_blank" rel="noopener noreferrer">
          {file.type}
        </a>
      </Typography>
    ));
  };

  const renderOptions = (options) => {
    if (!options) return null;
    return (
      <Box>
        {options.map((option, index) => (
          <Paper key={index} sx={{ padding: 1, margin: 1, display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" align="left" marginX={3}>{option}</Typography>
          </Paper>
        ))}
      </Box>
    );
  };

  const renderPostContent = (post) => {
    if (!post) return null;
    switch (post.type) {
      case 'Poll':
        return <PollDisplay poll={post} />;
      default:
        return <Typography variant="body1" align="left" marginX={3}>{post.content}</Typography>;
    }
  };
  if(loading) {
    return <CircularProgress />;
  }
  if (!course) {
    return <Typography variant="h6">Course not found.</Typography>;
  }

  return (
    <StyledContainer>
      <CourseHeader>
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2 }} align="left">
          {course.title}
        </Typography>
        <Typography variant="body1" align="left">
          Description: {course.description}
        </Typography>
      </CourseHeader>
      <Grid container spacing={2} justifyContent="space-evenly" alignItems="flex-start">
        <LiveMeet code={course.code} />
        <Grid item xs={12} md={8}>
          <Box>
            <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
              Class Stream
            </Typography>
            {sortedPosts.length === 0 && <Typography variant="body1">No posts yet.</Typography>}
            {sortedPosts.map((post) => (
              <PostCard key={post._id}>
                {post.tags && <Box mt={2}>{renderTags(post.tags)}</Box>}
                <CardContent sx={{ padding: '8px' }}>
                  <List sx={{ padding: 0 }}>
                    <ListItem sx={{ padding: 0 }}>
                      <ListItemAvatar>
                        <Avatar>{post.author_name ? post.author_name[0] : '?'}</Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={post.title}
                        secondary={`Posted by ${post.author_name ? post.author_name : 'Unknown'}`}
                        sx={{ margin: 0 }}
                      />
                    </ListItem>
                  </List>
                  <Divider variant="inset" />
                  {renderPostContent(post)}
                  {post.files && <Box mt={2}>{renderFiles(post.files)}</Box>}
                </CardContent>
              </PostCard>
            ))}
          </Box>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default ClassStream;
