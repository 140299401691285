import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ClassroomHeader from './classroomHeader';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { styled } from '@mui/material/styles';
import ClassStream from './ClassStream';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import apiClient from '../../config/AxiosConfig';
import People from './People/PeopleMain';
import {jwtDecode} from 'jwt-decode';
import CoursePost from './CoursePost';

export default function CoursePage({ title }) {
    const [value, setValue] = useState('1');
    const [courseData, setCourseData] = useState({});
    const { code } = useParams();  // Destructure the course code from useParams
    const navigate = useNavigate();
    const [userType, setUserType] = useState('student'); // ['student', 'teacher', 'admin']

    //get role from token
    useEffect(() => {
        const jwtToken = localStorage.getItem('jwtToken');
        if (!jwtToken) {
            navigate('/');
        }
        const tokenPayload = jwtDecode(jwtToken);
        setUserType(tokenPayload.role);
    }, []);
    useEffect(() => {
        const fetchCourseData = async () => {
            try {
                const response = await apiClient.get(`/getCourseDetails/${code}`);
                setCourseData(response.data.course);
            } catch (error) {
                console.error(error);
                if (error.response?.status === 404) {
                    alert('Course not found.');
                    navigate('/classroom');
                } else if (error.response?.status === 403) {
                    alert('You are not authorized to view this course.');
                    navigate('/classroom');
                } else {
                    alert('An error occurred while fetching course data.');
                    navigate('/classroom');
                }
            }
        };
        fetchCourseData();
    }, [code, navigate]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <ClassroomHeader />
            <TabContext value={value} sx={{ flexGrow: 1 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', justifyContent: 'center', marginX: 4 }}>
                    <TabList onChange={handleChange}>
                        <Tab label="Stream" value="1" />
                        <Tab label="Course Work" value="2" disabled={userType === 'student'} />
                        <Tab label="People" value="3" />
                    </TabList>
                </Box>
                <TabPanel value="1" sx={{ border: '1px solid #ccc', borderRadius: 1, margin: 1 }}><ClassStream course={courseData}/></TabPanel>
                <TabPanel value="2">
                    <CoursePost code={code}/>
                    
                </TabPanel>
                <TabPanel value="3"><People code={code}/></TabPanel>
            </TabContext>
        </Box>
    );
}
